import { FC, InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { InputStatusProp, selectStyles } from './styles'

export const StyledSelect = styled.select<InputStatusProp>`
  ${selectStyles}
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 40 40' width='35' height='35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.625 18.125C17.3077 18.4659 20 21.875 20 21.875C20 21.875 22.6923 18.4659 24.375 18.125' stroke='%23454A54' stroke-width='1.5'/%3E%3C/svg%3E%0A");
  background-position: 97.5% 52.5%;
  padding-right: 2rem;
  background-repeat: no-repeat;
  &.empty {
    color: ${(p) => p.theme.colors.shades[500]};
  }
`

export type SelectProps = InputStatusProp & InputHTMLAttributes<HTMLSelectElement>

export const Select: FC<SelectProps> = ({ value, ...rest }) => (
  <StyledSelect
    className={value === '' ? 'empty' : ''}
    data-field-id={rest.name}
    value={value}
    {...rest}
  />
)
