import { rem } from 'polished'
import { FC } from 'react'
import { cn } from '@lib/cn'
import { ConfirmationProps } from '.'
import { Link } from '../Link'
import { Text } from '../Typography'
import { NotificationIcon } from './Icon'
import { NotificationLabel } from './Label'

export const Confirmation: FC<ConfirmationProps> = ({
  description,
  label,
  sublabel,
  onClickAction,
  actionLabel,
}) => {
  return (
    <div className={cn('flex items-center gap-3', 'sm:gap-4')}>
      <NotificationIcon icon="check" />

      <div className="overflow-hidden">
        <Text variant="micro" as="div" mb={[1, rem(3)]}>
          {description}
        </Text>
        <NotificationLabel className="w-full">
          <b>{label}</b>
          {sublabel && ' • '}
          {sublabel}
        </NotificationLabel>
      </div>
      {actionLabel && (
        <div className="flex justify-end">
          <Link as="button" variant="grey" onClick={onClickAction}>
            {actionLabel}
          </Link>
        </div>
      )}
    </div>
  )
}
