// @ts-strict-ignore
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import React, { FC } from 'react'
import { useInView } from 'react-intersection-observer'
import { LinariaText } from '@components/gassan-ui/Typography/LinariaText'
import { ListImageLink } from '../utils/types'
import * as styles from './styles'

type HeaderImageListColumnProps = {
  title: string
  readMoreLink?: React.ReactNode
  links: ListImageLink[]
}

export const HeaderImageListColumn: FC<HeaderImageListColumnProps> = ({
  title,
  readMoreLink,
  links,
}) => {
  const { t } = useTranslation('navigation')
  const [ref, lastItemInView] = useInView()

  return (
    <div className={styles.parent}>
      <LinariaText variant="small" color="shade800" mb="4">
        {title}
      </LinariaText>
      <ul className={styles.list}>
        {links.map((item, key) => {
          const itemUrl = item.url.startsWith('/') ? item.url : `/${item.url}`
          let href = itemUrl

          // Special exception for Tudor, link shouldn't be altered everywhere,
          // just here and on the House of GASSAN brands page
          if (href === '/tudor') {
            href = t('about-tudor')
          }

          return (
            <li key={key}>
              <Link href={href} passHref>
                <a className={styles.link}>
                  <div className={styles.mobileImage}>
                    <Image alt={item.label} src={item.src} layout="fixed" height={20} width={20} />
                  </div>
                  <div className={styles.desktopImage}>
                    <Image alt={item.label} src={item.src} layout="fixed" height={40} width={40} />
                  </div>
                  <span className={styles.linkText} data-text>
                    {item.label}
                  </span>
                </a>
              </Link>
            </li>
          )
        })}
        <li ref={ref} />
      </ul>
      {!lastItemInView && <div className={styles.scrollIndicator} />}
      {readMoreLink && <div className={styles.readMore}>{readMoreLink}</div>}
    </div>
  )
}
