// @ts-strict-ignore
import get from 'lodash.get'
import navigationDE from '../public/locales/de/navigation.json'
import navigationEN from '../public/locales/en/navigation.json'
import navigationNL from '../public/locales/nl/navigation.json'

export const getNavigationInLocale = (key: string, locale: any): string => {
  switch (locale) {
    case 'de':
      return get(navigationDE, key)
    case 'en':
      return get(navigationEN, key)
    case 'nl':
      return get(navigationNL, key)
  }
}
