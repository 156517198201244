import { useCallback, useEffect, useState } from 'react'
import { PlayMode } from '..'

export function useVideoPlayer(
  ref: React.RefObject<HTMLVideoElement>,
  playMode: PlayMode,
  inView: boolean,
) {
  const [status, setStatus] = useState<'idle' | 'loading' | 'playing'>('idle')
  const [loopedAudioMuted, setLoopedAudioMuted] = useState(true)
  const requestVideoPlay = useCallback(() => {
    if (ref.current) {
      setStatus('loading')
      ref.current.play()
      // Remove focus from the play button to the player
      ref.current.focus()
    }
  }, [ref])

  useEffect(() => {
    if (['auto-play', 'loop-muted', 'loop-muted-toggle-audio'].includes(playMode) && inView) {
      requestVideoPlay()
    }
  }, [inView, playMode, requestVideoPlay])

  useEffect(() => {
    const player = ref.current

    if (!player) return

    const handlePlayEvent = () => {
      setStatus('playing')
    }

    // Listen to the playing event (can be triggered from internal or external play button)
    player.addEventListener('playing', handlePlayEvent)
    return () => {
      player.removeEventListener('playing', handlePlayEvent)
    }
  }, [ref])

  function toggleAudio() {
    const newAudioStatus = !loopedAudioMuted

    if (ref.current) {
      ref.current.muted = newAudioStatus
      setLoopedAudioMuted(newAudioStatus)
    }
  }

  return {
    loopedAudioMuted,
    toggleAudio,
    requestVideoPlay,
    status,
  }
}
