import { Form, Formik, FormikProps } from 'formik'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'
import * as Yup from 'yup'
import { getUrlSlugInLocale } from '@lib/get-url-slug-in-locale'
import { useFinishedButtonState } from '@lib/hooks/useFinishedButtonState'
import { useIsMounted } from '@lib/hooks/useIsMounted'
import { email } from '@lib/regexes'
import { Button, Field, Heading } from '@gassan-ui'

type FormValues = {
  email: string
}

const schema = Yup.object().shape({
  email: Yup.string().required('forms.errors.email').matches(email, 'forms.errors.email'),
})

const NewsletterSignup: FC = () => {
  const router = useRouter()
  const {
    t,
    i18n: { language },
  } = useTranslation(['footer', 'forms', 'other'])
  const isMounted = useIsMounted()
  const [isLoading, setIsLoading] = useState(false)
  const isFinished = useFinishedButtonState(isLoading)
  const status = isLoading ? 'loading' : isFinished ? 'finished' : 'idle'

  return (
    <>
      <Heading
        variant="h4"
        as="h4"
        mb={{
          _: '0.5rem',
          small: '1rem',
        }}
      >
        {t('stayUpdated', { ns: 'footer' })}
      </Heading>
      <Formik
        validateOnBlur
        validateOnChange={false}
        initialValues={{
          email: '',
        }}
        validationSchema={schema}
        onSubmit={async (values, { resetForm }) => {
          setIsLoading(true)

          router
            .push({
              pathname: `/${language}/${getUrlSlugInLocale('newsletter', language)}`,
              query: { email: values.email },
            })
            .then(() => {
              window.scrollTo({ top: 0, left: 0 })
              if (isMounted) {
                resetForm()
                setIsLoading(false)
              }
            })
        }}
      >
        {({ errors, touched, values }: FormikProps<FormValues>) => (
          <Form>
            <Field
              type="email"
              field="input"
              id="signup-email"
              status={errors.email && touched.email ? 'error' : null}
              name="email"
              label={t('labels.email', { ns: 'forms' })}
              value={values.email}
            />
            <Button variant="dark" type="submit" status={status} style={{ width: '100%' }}>
              {t('signup', { ns: 'other' })}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default NewsletterSignup
