import { cn } from '@lib/cn'
import * as Dialog from '@radix-ui/react-dialog'
import { FC } from 'react'
import CookieSettings from './settings/index'

interface Props {
  onClose: () => void
  isVisible?: boolean
}

const Modal: FC<Props> = ({ onClose, isVisible = true }) => {
  if (!isVisible) return null

  return (
    <Dialog.Root
      defaultOpen
      onOpenChange={(open) => {
        if (!open) {
          onClose()
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[100] h-screen w-screen bg-shade-800/30">
          <Dialog.Content
            className={cn(
              'relative left-0 top-0 h-screen w-screen overflow-y-auto border-shade-800 bg-pampasLight',
              'left-[50%] top-[50%] h-auto -translate-x-1/2 -translate-y-1/2 md:max-h-[80vh] md:max-w-[40rem]',
            )}
          >
            <CookieSettings />
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default Modal
