// @ts-strict-ignore
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Button, Heading, Text } from '@gassan-ui'
import { cn } from '@lib/cn'
import { Options, getCookieSettings, updateCookieSettings } from '@lib/cookies'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'
import { Checkbox } from '../Checkbox'
import { CookiesLabel } from './Label'
import { CookiesSingleSetting } from './SingleSetting'

const Settings: FC = () => {
  const [acceptedCookies, setAcceptedCookies] = useState<Options[]>(
    getCookieSettings() || ['functional'],
  )
  const { t } = useTranslation(['cookies', 'navigation'])

  const savePreferences = () => {
    updateCookieSettings(acceptedCookies)
    window.location.reload()
  }

  const toggle = (e, type: 'personal:rolex' | 'personal' | 'analytical') => {
    if (e.target.checked && !acceptedCookies.includes(type)) {
      setAcceptedCookies([...acceptedCookies, type])
    } else {
      setAcceptedCookies(acceptedCookies.filter((val) => val !== type))
    }
  }

  return (
    <div
      className={cn(
        'relative px-4 pb-16 pt-7',
        'sm:px-12 sm:pb-10 sm:pt-12',
        'md:px-16 md:pb-12 md:pt-16',
      )}
    >
      <div className="mb-4">
        <Heading variant="h3" mb={{ _: '1rem', large: '1.25rem' }}>
          {t('popupTitle', { ns: 'cookies' })}
        </Heading>
        <Text>
          {t('popupDescription', { ns: 'cookies' })}{' '}
          <LocalizedLink href={t('privacyPolicy', { ns: 'navigation' })}>
            <a>{t('popupDescriptionLink', { ns: 'cookies' })}</a>
          </LocalizedLink>
          .
        </Text>
      </div>
      <CookiesSingleSetting>
        <Checkbox
          id="personal"
          checked={acceptedCookies.includes('personal')}
          onChange={(e) => toggle(e, 'personal')}
        />
        <CookiesLabel htmlFor="personal">
          <Text variant="regularBold">{t('personalCookies', { ns: 'cookies' })}</Text>
          <Text mb="0" color="shades.500">
            {t('personalDesc', { ns: 'cookies' })}
          </Text>
        </CookiesLabel>
      </CookiesSingleSetting>
      <CookiesSingleSetting>
        <Checkbox
          id="analytical"
          checked={acceptedCookies.includes('analytical')}
          onChange={(e) => toggle(e, 'analytical')}
        />
        <CookiesLabel htmlFor="analytical">
          <Text variant="regularBold">{t('analyticalCookies', { ns: 'cookies' })}</Text>
          <Text mb="0" color="shades.500">
            {t('analyticalDesc', { ns: 'cookies' })}
          </Text>
        </CookiesLabel>
      </CookiesSingleSetting>
      <CookiesSingleSetting>
        <Checkbox checked disabled />
        <CookiesLabel>
          <Text variant="regularBold">{t('functionalCookies', { ns: 'cookies' })}</Text>
          <Text mb="0" color="shades.500">
            {t('functionalDesc', { ns: 'cookies' })}
          </Text>
        </CookiesLabel>
      </CookiesSingleSetting>
      <CookiesSingleSetting>
        <Checkbox
          id="personal:rolex"
          checked={acceptedCookies.includes('personal:rolex')}
          onChange={(e) => toggle(e, 'personal:rolex')}
        />
        <CookiesLabel htmlFor="personal:rolex">
          <Text variant="regularBold">{t('rolexCookies', { ns: 'cookies' })}</Text>
          <Text
            mb="0"
            color="shades.500"
            dangerouslySetInnerHTML={{ __html: t('rolexDesc', { ns: 'cookies' }) }}
          />
        </CookiesLabel>
      </CookiesSingleSetting>
      <div className="mt-4 lg:mt-8">
        <Button variant="dark" className="min-w-full" onClick={() => savePreferences()}>
          {t('savePreferences', { ns: 'cookies' })}
        </Button>
      </div>
    </div>
  )
}

export default Settings
