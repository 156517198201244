import { MeQuery, useMeQuery } from '@generated'
import { useCallback, useEffect } from 'react'
import create from 'zustand'
import { useResetClient } from '@components/UrqlClientProvider'
import { useCheckout } from '@components/_checkout/CheckoutProvider'

type UseAuthStoreState = {
  isLoggedIn: boolean
  setIsLoggedIn: (isLoggedIn: boolean) => void
}

type ReturnUseAuth = {
  isLoading: boolean
  isLoggedIn: boolean
  fetchMeQuery: () => void
  logout: () => void
  login: () => void
  me: MeQuery['me']
}

const useStore = create<UseAuthStoreState>((set) => ({
  isLoggedIn: false,
  setIsLoggedIn: (isLoggedIn: boolean) => set({ isLoggedIn }),
}))

export function useAuth(): ReturnUseAuth {
  const { isLoggedIn, setIsLoggedIn } = useStore()
  const { resetClient } = useResetClient()
  const { clear } = useCheckout()
  const [{ data, fetching }, refetch] = useMeQuery({
    requestPolicy: 'network-only',
  })
  const hasLoadedMeData = typeof data !== 'undefined'
  const isLoading = !hasLoadedMeData || fetching

  const login = useCallback(async () => {
    setIsLoggedIn(true)
    refetch()
  }, [refetch, setIsLoggedIn])

  useEffect(() => {
    async function verifyLogin() {
      const res = await fetch('/api/auth/verify')
      const data = await res.json()

      if (data && data.isLoggedIn) {
        login()
      }
    }

    verifyLogin()
  }, [login])

  const logout = useCallback(async () => {
    await fetch('/api/auth/logout').then((res) => {
      // res.status should be 204
      if (res.status === 204) {
        clear()
        resetClient()
        setIsLoggedIn(false)
      } else {
        if (process.env.NEXT_PUBLIC_STAGE !== 'production') {
          console.error('Response status of /logout is not 204')
        }
      }
    })
  }, [resetClient, clear, setIsLoggedIn])

  return {
    isLoggedIn,
    isLoading,
    login,
    logout,
    fetchMeQuery: refetch,
    me: data?.me,
  }
}
