import { FC } from 'react'
import { cn } from '@lib/cn'
import { SimpleProps } from '.'
import { NotificationIcon } from './Icon'
import { NotificationLabel } from './Label'

export const Simple: FC<SimpleProps> = ({ label }) => {
  return (
    <div className={cn('flex items-center gap-3', 'gap-4')}>
      <NotificationIcon icon="check" />
      <div className="overflow-hidden">
        <NotificationLabel className="font-bold">{label}</NotificationLabel>
      </div>
    </div>
  )
}
