import NextLink from 'next/link'
import { FC } from 'react'
import { Link } from '@gassan-ui'
import { DiamondsContent } from '../../../../lib/kontent/navigation-helpers/get-diamonds-content'
import { HeaderImageColumn } from '../image-column'
import { HeaderLinkColumn } from '../link-column'

type HeaderShopMenuDiamondsProps = DiamondsContent

export const HeaderShopMenuDiamonds: FC<HeaderShopMenuDiamondsProps> = ({
  linkList,
  imageLinkFirstColumn,
  imageLinkSecondColumn,
}) => {
  return (
    <>
      <HeaderLinkColumn
        title={linkList.title}
        readMoreLink={
          linkList.viewMore && (
            <NextLink href={linkList.viewMore.url} passHref>
              <Link variant="blue">{linkList.viewMore.label}</Link>
            </NextLink>
          )
        }
        links={linkList.links}
        linkSpace="regular"
      />
      <HeaderImageColumn {...imageLinkFirstColumn} />
      <HeaderImageColumn {...imageLinkSecondColumn} showOnlyOnXlarge />
    </>
  )
}
