import { FC } from 'react';
import { parse } from '@config/theme';
type PageNavigationHamburgerProps = {
  isActive: boolean;
  handleClick: () => void;
};
export const PageNavigationHamburger: FC<PageNavigationHamburgerProps> = ({
  isActive,
  handleClick
}) => {
  return <button className={button} data-is-active={isActive ? '' : undefined} onClick={handleClick}>
      <span />
      <span />
    </button>;
};
const button = parse({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}, "bt36zzn");

require("./Hamburger.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Hamburger.tsx");