import { ButtonHTMLAttributes, FC } from 'react'
import { button } from './MenuButton.styles'

const specials = ['g', 'j', 'p', 'q', 'y', 'Q']

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: string
  variant?: 'large' | 'regular'
  isActive?: boolean
}

const MenuButton: FC<Props> = ({ children, variant = 'large', isActive, ...rest }) => {
  return (
    <button
      className={button}
      data-status-active={isActive ? '' : undefined}
      data-variant={variant}
      dangerouslySetInnerHTML={{
        __html: children
          .split('')
          .map((letter) => (specials.includes(letter) ? `<span>${letter}</span>` : letter))
          .join(''),
      }}
      {...rest}
    />
  )
}
export default MenuButton
