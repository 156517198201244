// @ts-strict-ignore
import { useTranslation } from 'next-i18next'
import { FC, useMemo } from 'react'
import { cn } from '@lib/cn'
import { useLocalization } from '@components/Localization'
import { MenuButton } from '@gassan-ui'

const flag = {
  en: { image: '/logos/flag_en.png', text: 'English' },
  nl: { image: '/logos/flag_nl.png', text: 'Dutch' },
  de: { image: '/logos/flag_de.png', text: 'German' },
}

const LanguageSwitch: FC = () => {
  const { setLang } = useLocalization()
  const {
    t,
    i18n: { language },
  } = useTranslation('global')

  const items = useMemo(() => {
    return [
      {
        value: 'nl',
        label: (
          <span className="flex items-center">
            <img src={flag['nl'].image} className="mr-2 h-4 w-4" alt="flag of nl" />
            {t(flag['nl'].text)}
          </span>
        ),
      },
      {
        value: 'en',
        label: (
          <span className="flex items-center">
            <img src={flag['en'].image} className="mr-2 h-4 w-4" alt="flag of en" />
            {t(flag['en'].text)}
          </span>
        ),
      },
      {
        value: 'de',
        label: (
          <span className="flex items-center">
            <img src={flag['de'].image} className="mr-2 h-4 w-4" alt="flag of de" />
            {t(flag['de'].text)}
          </span>
        ),
      },
    ]
  }, [t])

  return (
    <div className="hidden md:block">
      <MenuButton items={items} value={language} onChange={setLang} align="right">
        <span
          className={cn(
            'duration-[400ms] relative ml-2 flex h-10 w-10 content-center items-center transition-all',
            'sm:ml-3 xl:ml-4',
            'hover:!opacity-100 focus:!opacity-100',
          )}
          data-secondary-nav-element
        >
          <img
            className="w-full max-w-[1.5rem]"
            src={flag[language]?.image}
            alt={`flag of ${language}`}
          />
        </span>
      </MenuButton>
    </div>
  )
}

export default LanguageSwitch
