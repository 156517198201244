// @ts-strict-ignore
import { AnimatePresence } from 'framer-motion'
import { FC, useRef } from 'react'
import { NavigationContent } from '@lib/kontent/navigation-helpers/normalize-navigation-content'
import { HeaderDiamondsMenu } from '@components/Page/Header/DiamondsMenu'
import { HeaderDropdown } from '@components/Page/Header/Dropdown'
import { HeaderHouseOfGassanMenu } from '@components/Page/Header/HouseOfGassanMenu'
import MobileNav from '@components/Page/Header/mobile-nav'
import { HeaderShopMenu } from '@components/Page/Header/shop-menu'
import { Style, Variant, Visibility } from '.'
import * as styles from './HeaderContent.styles'
import { PreHeader } from './PreHeader'
import { HeaderNavigation } from './navigation'
import Search from './search'
import { useHeaderContent } from './utils/useHeaderContent'

export type NavActiveOptions =
  | 'shop'
  | 'diamonds'
  | 'search'
  | 'house-of-gassan'
  | 'mobile-nav'
  | null

interface Props {
  variant: Variant
  visibility: Visibility
  headerStyle: Style
  content: NavigationContent
}

export const HeaderContent: FC<Props> = ({ variant, visibility, headerStyle, content }) => {
  const ref = useRef<HTMLElement>(null)
  const { active, handleSetActive, menuIsActive } = useHeaderContent({ ref })

  return (
    <>
      <div className={styles.background}></div>
      <PreHeader />
      <header
        className={styles.header}
        ref={ref}
        data-variant={variant}
        data-header-style={headerStyle}
        data-visibility={visibility}
        data-has-dropdown={active !== null ? '' : undefined}
      >
        <HeaderNavigation onClickItem={handleSetActive} active={active} />
      </header>
      <AnimatePresence>
        <HeaderDropdown
          menuIsActive={menuIsActive}
          onRequestClose={() => handleSetActive(null)}
          variant={active === 'search' ? 'fullscreen-on-mobile' : 'default'}
        >
          <HeaderShopMenu isVisible={active === 'shop'} content={content} />
          <HeaderDiamondsMenu isVisible={active === 'diamonds'} {...content.diamonds} />
          <HeaderHouseOfGassanMenu
            isVisible={active === 'house-of-gassan'}
            columns={content.houseOfGassan}
          />
          {active === 'search' && <Search onRequestClose={() => handleSetActive(null)} />}
          <MobileNav isVisible={active === 'mobile-nav'} content={content} />
        </HeaderDropdown>
      </AnimatePresence>
    </>
  )
}
