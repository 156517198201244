import styled, { keyframes } from 'styled-components'
import { Box } from './Layout'
import { theme } from './theme'

const loadingAnimation = keyframes`
  0% {
    opacity: 1
  }
  50% {
    opacity: .5
  }
  100% {
    opacity: 1
  }
`

export const LoadingBar = styled(Box)`
  position: relative;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: ${theme.colors.shades[100]};
  animation: ${loadingAnimation} 1.25s linear infinite;
`

LoadingBar.defaultProps = {
  height: '1rem',
  width: '100%',
  borderRadius: '2px',
  display: 'flex',
}
