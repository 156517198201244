import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import LocalizedLink from '@components/Localization/LocalizedLink'
import RolexClock from './navigation/RolexClock'

type RolexOrTudorPlaqueProps = {}

export const RolexOrTudorPlaque: FC<RolexOrTudorPlaqueProps> = () => {
  const { asPath } = useRouter()
  const { t } = useTranslation('navigation')
  const tudorLinks = [t('about-tudor'), '/tudor']
  const isTudorPage = tudorLinks.some((link) => asPath.includes(link))

  if (asPath.includes('cartier')) return null

  return (
    <>
      {isTudorPage ? (
        <Link href={t('about-tudor')} passHref legacyBehavior>
          <a
            title={`${t('goTo', { ns: 'global' })} Tudor`}
            data-secondary-nav-element
            className="duration-400 flex h-[70px] w-[150px] items-center justify-center transition-opacity hover:!opacity-100"
          >
            <img
              src="/images/tudor-plaque-240x120_en.jpg"
              className="h-auto w-[120px]"
              alt="Official retailer Tudor"
            />
          </a>
        </Link>
      ) : (
        <LocalizedLink href="/rolex" passHref>
          <a
            title={`${t('goTo', { ns: 'global' })} Rolex`}
            data-secondary-nav-element
            className="duration-400 transition-opacity hover:!opacity-100"
          >
            <RolexClock />
          </a>
        </LocalizedLink>
      )}
    </>
  )
}
