import { FC } from 'react'
import styled from 'styled-components'
import { getKontentImage } from '@lib/kontent'
import { Box, Container, Flex, Heading, Image, breakpoints } from '@gassan-ui'

const ImageContainer = styled(Flex)`
  z-index: 1;
  &::before {
    padding-top: 65%;
    content: '';
    display: block;
    width: 100%;

    @media screen and (min-width: ${breakpoints.medium}) {
      padding-top: 57%;
    }
    @media screen and (min-width: ${breakpoints.large}) {
      padding-top: 27%;
      padding-bottom: 3rem;
    }
    @media screen and (min-width: ${breakpoints.xlarge}) {
      padding-bottom: 0;
    }

    @media screen and (max-width: ${breakpoints.medium}) and (orientation: landscape) {
      overflow: hidden;
      padding-top: 30%;
    }
  }

  > div {
    position: absolute;
  }
`

type Props = {
  title: string
  description: string
  alt: string
  mobileSrc: string
  desktopSrc: string
  textAlign?: 'left' | 'center'
}

const PageHero: FC<Props> = ({
  title,
  description,
  mobileSrc,
  desktopSrc,
  alt,
  textAlign = 'center',
}) => {
  const sources = [
    getKontentImage(mobileSrc, { width: 375 }),
    getKontentImage(mobileSrc, { width: 750 }),
    getKontentImage(desktopSrc, { width: 1440 }),
    getKontentImage(desktopSrc, { width: 1920 }),
    getKontentImage(desktopSrc, { width: 2560 }),
  ]

  return (
    <ImageContainer
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      position="relative"
    >
      <Image
        alt={alt}
        sources={sources}
        className="absolute inset-0 z-[1]"
        placeholderSrc={getKontentImage(mobileSrc, { width: 50 })}
      />
      <Container
        position="absolute"
        top="0"
        left="50%"
        style={{ transform: `translate(-50%, 0)`, zIndex: 2 }}
        height="100%"
        display="flex"
        alignItems="center"
        textAlign={textAlign}
        justifyContent={textAlign === 'left' ? 'flex-start' : 'center'}
      >
        <Box width={{ _: '100%', large: '90%' }} mx="auto">
          <Box
            width="100%"
            maxWidth={
              textAlign === 'left'
                ? { _: 'calc(100% - 2rem)', small: '35rem' }
                : { _: 'calc(100% - 2rem)', small: '39rem', large: '50rem' }
            }
            mt={textAlign === 'left' ? 'auto' : { _: 'auto', large: '-2rem', xlarge: 'auto' }}
            left={textAlign === 'left' ? 0 : '50%'}
            top="50%"
            style={{
              transform: textAlign === 'left' ? 'translateY(-50%)' : 'translate(-50%, -50%)',
            }}
            position="absolute"
          >
            <Heading variant="h1" as="h1" color="white" mb="1rem">
              {title}
            </Heading>
            <Heading variant="h4" as="p" color="white">
              {description}
            </Heading>
          </Box>
        </Box>
      </Container>
    </ImageContainer>
  )
}

export default PageHero
