import { rem, rgba } from 'polished'
import { FC } from 'react'
import styled, { css } from 'styled-components'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Box, Span, breakpoints } from '@gassan-ui'

const Image = styled.img<{ variant: string }>`
  transition: opacity 0.2s ease;
  width: ${rem(56)};
  height: ${rem(56)};

  object-position: center center;
  border-radius: 2px;

  @media screen and (min-width: ${breakpoints.small}) {
    width: ${rem(72)};
    height: ${rem(72)};
  }

  ${(p) =>
    p.variant === 'product' &&
    css`
      background: #fff;
      object-fit: contain;
    `}
  ${(p) =>
    p.variant === 'brand' &&
    css`
      background: #fff;
      object-fit: contain;
      padding: 1rem;
    `}
  ${(p) =>
    p.variant === 'category' &&
    css`
      background: #fff;
      object-fit: cover;
    `}
`
const Anchor = styled.a`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus {
      ${Image} {
        opacity: 0.8;
      }
    }
  }
`

const Content = styled(Box)`
  overflow: hidden;
  width: calc(100% - ${rem(72)});
  overflow-x: hidden;
  @media screen and (min-width: ${breakpoints.small}) {
    width: calc(100% - ${rem(88)});
  }
`

Content.defaultProps = {
  pl: 3,
  display: 'flex',
  flexDirection: 'column',
  py: 2,
}

const StyledSpan = styled(Span)`
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`
StyledSpan.defaultProps = {
  as: 'div',
  mb: 0,
  lineHeight: 1,
  fontFamily: 'body',
  color: 'shades.800',
}

type Props = {
  image: string
  title: string
  id?: string
  subtitle?: string
  price?: string
  href: string

  variant: 'product' | 'brand' | 'category'
}

const Result: FC<Props> = ({ image, title, subtitle, id, price, href, variant }) => {
  const inner = (
    <Anchor href={href}>
      {image ? (
        <Image src={image} loading="lazy" alt="" variant={variant} />
      ) : (
        <Box
          width={{ _: '3.5rem', small: '4.5rem' }}
          height={{ _: '3.5rem', small: '4.5rem' }}
          backgroundColor={rgba('#fff', 0.5)}
        />
      )}
      {variant === 'product' && (
        <Content justifyContent="center">
          <StyledSpan pb={2} fontSize="1rem" fontWeight="bold">
            {title}
          </StyledSpan>
          <StyledSpan pb={price ? 2 : 0}>{subtitle}</StyledSpan>
          {price && <StyledSpan fontSize={rem(12)}>{price}</StyledSpan>}
        </Content>
      )}
      {variant === 'brand' && (
        <Content justifyContent="center">
          <StyledSpan py={2} fontSize={rem(20)}>
            {title}
          </StyledSpan>
        </Content>
      )}
      {variant === 'category' && (
        <Content justifyContent="center">
          <StyledSpan py={2} fontWeight="bold">
            {title}
          </StyledSpan>
        </Content>
      )}
    </Anchor>
  )

  return <LocalizedLink href={href}>{inner}</LocalizedLink>
}

export default Result
