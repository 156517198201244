// @ts-strict-ignore
import shouldForwardProp from '@styled-system/should-forward-prop'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { breakpoints, theme } from '../theme'
import { linkStyles } from './Text'
import { StyledTextProps, allStyledSystemFunctions } from './config'

const headingBase = css`
  font-family: ${theme.fonts.heading};
  letter-spacing: 0.03em;

  a {
    ${linkStyles}
  }
`
export const headingXl = css`
  ${headingBase};
  line-height: 1.2;
  font-size: 1.75rem;
  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: 3rem;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    letter-spacing: 0.02em;
    font-size: 3.5rem;
  }
  @media screen and (min-width: ${breakpoints.xlarge}) {
    font-size: 4.5rem;
  }
`
export const heading1 = css`
  ${headingBase};
  line-height: 1.3;
  font-size: 1.5rem;
  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: 2.5rem;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    font-size: 2.625rem;
  }
  @media screen and (min-width: ${breakpoints.xlarge}) {
    font-size: 3rem;
  }
`
export const heading2 = css`
  ${headingBase};
  line-height: 1.3;
  font-size: 1.25rem;
  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: 2rem;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    font-size: 2.125rem;
  }
  @media screen and (min-width: ${breakpoints.xlarge}) {
    font-size: 2.5rem;
  }
`
export const heading3 = css`
  ${headingBase};
  line-height: 1.4;
  font-size: 1.125rem;
  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: 1.5rem;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    font-size: 1.625rem;
  }
  @media screen and (min-width: ${breakpoints.xlarge}) {
    font-size: 2rem;
  }
`
export const heading4 = css`
  ${headingBase};
  line-height: 1.4;
  font-size: 1rem;
  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: 1.25rem;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    font-size: 1.375rem;
  }
  @media screen and (min-width: ${breakpoints.xlarge}) {
    font-size: 1.5rem;
  }
`
export const heading5 = css`
  ${headingBase};
  line-height: 1.4;
  font-size: 0.875rem;
  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: 1.125rem;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    font-size: 1.25rem;
  }
`

const headingStyles = {
  h1: heading1,
  h2: heading2,
  h3: heading3,
  h4: heading4,
  h5: heading5,
  hXl: headingXl,
}

type HeadingProps = React.HTMLAttributes<HTMLElement> &
  StyledTextProps & {
    variant: keyof typeof headingStyles
  }

export const Heading: FC<HeadingProps> = styled<React.FC<HeadingProps>>((props: HeadingProps) => (
  <div {...props} />
)).withConfig({ shouldForwardProp })<HeadingProps>`
  ${allStyledSystemFunctions};
  ${(p) => headingStyles[p.variant]}
`

Heading.defaultProps = {
  mb: ['1.5rem', '1.5rem', '2rem', '2rem', '2.5rem'],
  color: 'shades.800',
}
