// @ts-strict-ignore
import shouldForwardProp from '@styled-system/should-forward-prop'
import Downshift from 'downshift'
import React, { FC } from 'react'
import styled from 'styled-components'
import { selectStyles } from '../Form/styles'
import { Icon } from '../Icon'
import { Box, Flex } from '../Layout'
import { menuListStyles, menuStyles } from '../MenuButton/Menu'
import { MenuItem } from '../MenuButton/MenuItem'
import { breakpoints } from '../theme'

export const ListboxButton = styled(Box).withConfig({
  shouldForwardProp,
})<{ isOpen; hasError }>`
  ${selectStyles};
  border: 1px solid ${(p) => (p.hasError ? p.theme.colors.error : p.theme.colors.shades[400])};
  user-select: none;
  padding: 0 2rem 0 0.75rem;

  &::after {
    position: absolute;
    height: 3rem;
    width: 3rem;
    right: 0;
    top: 0;
    transform: rotate(${(p) => (p.isOpen ? '180deg' : 0)});
    content: '';
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 40 40' width='35' height='35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.625 18.125C17.3077 18.4659 20 21.875 20 21.875C20 21.875 22.6923 18.4659 24.375 18.125' stroke='%23454A54' stroke-width='1.5'/%3E%3C/svg%3E%0A")
      no-repeat center center;

    @media screen and (min-width: ${breakpoints.small}) {
      height: 3.5rem;
      line-height: 3.5rem;
    }
  }
`
const Menu = styled(Box)`
  bottom: 0;
  ${menuStyles};
`
const MenuList = styled.ul`
  ${menuListStyles};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  li {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
`

type Item = {
  value: string
  label: React.ReactChild
  prefix?: React.ReactChild
}

export type ListboxProps = {
  items: Item[]
  value?: string
  onChange: (value: string) => void
  hasError?: boolean
}

export const Listbox: FC<ListboxProps> = ({
  items,
  value,
  onChange,
  hasError = false,
  children,
}) => {
  return (
    <Downshift
      selectedItem={null}
      itemToString={(item) => item && item.value}
      onSelect={(item) => {
        if (item) {
          onChange(item.value)
        }
      }}
    >
      {({ getToggleButtonProps, getMenuProps, getRootProps, getItemProps, isOpen }) => {
        return (
          <Box {...getRootProps()} position="relative">
            <ListboxButton {...getToggleButtonProps()} isOpen={isOpen} hasError={hasError}>
              {children}
            </ListboxButton>
            {isOpen && (
              <Menu {...getMenuProps()} className="listbox-menu" width="100%">
                <MenuList>
                  {items.map((item, index) => (
                    <MenuItem
                      {...getItemProps({ item, index })}
                      key={`item-${index}`}
                      isActive={item.value === value}
                    >
                      <Flex alignItems="center">
                        {item.prefix && <Flex mr=".75rem">{item.prefix}</Flex>}
                        {item.label}
                      </Flex>
                      {item.value === value && <Icon icon="check" />}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}
          </Box>
        )
      }}
    </Downshift>
  )
}
