import { useTranslation } from 'next-i18next'
import { rem } from 'polished'
import { FC } from 'react'
import { cn } from '@lib/cn'
import { Box, Text } from '@gassan-ui'
import { ErrorProps } from './'
import { NotificationIcon } from './Icon'

export const Error: FC<ErrorProps> = ({ label }) => {
  const { t } = useTranslation(['user-errors', 'notifications'])
  // If passed a user-error code, e.g. user-error:invalid-voucher-code then use that code to translate it
  const message = label.includes('user-error')
    ? t(label.replace('[GraphQL] ', ''), { ns: 'user-errors' }) ||
      t('refreshAndTryAgain', { ns: 'notifications' })
    : label
  return (
    <div className={cn('flex items-center gap-3', 'sm:grid-cols-[56px,auto] sm:gap-4')}>
      <Box display={['none', 'block']}>
        <NotificationIcon className="bg-error/20 text-error" icon="exclamation" />
      </Box>
      <Box>
        <Text variant="micro" as="div" mb={[1, rem(3)]} color="error">
          {t('somethingWentWrong', { ns: 'user-errors' })}
        </Text>
        <Text as="div" color="error" mb={0} fontWeight="bold">
          {message}
        </Text>
      </Box>
    </div>
  )
}
