import { rem } from 'polished'
import { css } from 'styled-components'
import { breakpoints } from '../theme'

export const linkStyle = css`
  font-family: ${(p) => p.theme.fonts.specials};
  font-size: ${rem(9)};
  text-transform: uppercase;
  letter-spacing: 0.28em;
  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: ${rem(11)};
  }
`

export const bodyRegularStyle = css`
  font-family: ${(p) => p.theme.fonts.body};
  font-size: 0.875rem;

  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: 1rem;
  }
`
