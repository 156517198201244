import { FC } from 'react';
import { parse } from '@config/theme';
import { RolexOrTudorPlaque } from '../RolexOrTudorPlaque';
const parent = parse({
  position: 'fixed',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  bg: 'pampas'
}, "ptnv55g");
const HeaderMobileNavFooter: FC = () => {
  return <footer className={parent}>
      <RolexOrTudorPlaque />
    </footer>;
};
export default HeaderMobileNavFooter;

require("./Footer.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Footer.tsx");