import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { useAuth } from '@lib/hooks/use-auth'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { TrustPilotWidget } from '@components/TrustPilotWidget'
import { Container, Icon } from '@components/gassan-ui'
import * as styles from './PreHeader.styles'

type PreHeaderProps = {}

export const PreHeader: FC<PreHeaderProps> = () => {
  const { t } = useTranslation('navigation')
  const { isLoggedIn, isLoading } = useAuth()
  return (
    <div className={styles.preHeader}>
      <Container className={styles.container}>
        <TrustPilotWidget
          templateId="5419b637fa0340045cd0c936"
          businessUnitId="552f9c6e0000ff00057ecd72"
          height="20px"
          width="230px"
        />
        <LocalizedLink href={t('secondaryNav.contact.url')}>
          <a className={styles.link}>
            <Icon icon="contact" size="2.5rem" />
            <span data-text>{t('secondaryNav.contact.title')}</span>
          </a>
        </LocalizedLink>
        {!isLoading && isLoggedIn ? (
          <LocalizedLink href={t('secondaryNav.myGassan.url')}>
            <a className={styles.link}>
              <span data-text>{t('secondaryNav.myGassan.title')}</span>
            </a>
          </LocalizedLink>
        ) : (
          <LocalizedLink href={t('account.myGassanInfo.url')}>
            <a className={styles.link}>
              <span data-text>{t('account.myGassanInfo.title')}</span>
            </a>
          </LocalizedLink>
        )}
        {!isLoading && isLoggedIn ? (
          <LocalizedLink href={t('secondaryNav.account.url')}>
            <a className={styles.link}>
              <Icon icon="profile" size="1.75rem" />
              <span data-text>{t('secondaryNav.account.title')}</span>
            </a>
          </LocalizedLink>
        ) : (
          <LocalizedLink href={t('account.login.url')}>
            <a className={styles.link}>
              <Icon icon="profile" size="1.75rem" />
              <span data-text>{t('account.login.title')}</span>
            </a>
          </LocalizedLink>
        )}
      </Container>
    </div>
  )
}
