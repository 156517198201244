// @ts-strict-ignore
import { cn } from '@lib/cn'
import { FC } from 'react'
import { Action } from './Action'
import { Confirmation } from './Confirmation'
import { Error } from './Error'
import { LanguageSwitch } from './LanguageSwitch'
import { Simple } from './Simple'

export type NotificationVariant =
  | 'image-action'
  | 'image-action-undo'
  | 'icon-action'
  | 'icon-action-undo'
  | 'confirmation'
  | 'simple-confirmation'
  | 'error'
  | 'language-switch'

export type ActionProps = {
  variant: 'image-action' | 'image-action-undo' | 'icon-action' | 'icon-action-undo'
  image?: string
  description: string
  label: string
  sublabel: string
  onClickAction: () => void
  actionLabel: string
}

export type ConfirmationProps = {
  variant: 'confirmation'
  description: string
  label: string
  sublabel?: string
  onClickAction?: () => void
  actionLabel?: string
}

export type SimpleProps = {
  variant: 'simple-confirmation'
  label: string
}

export type ErrorProps = {
  variant: 'error'
  label: string
}

export type LanguageSwitchProps = {
  variant: 'language-switch'
  onClickAction: () => void
}

export type NotificationBody =
  | ActionProps
  | ConfirmationProps
  | SimpleProps
  | ErrorProps
  | LanguageSwitchProps

type Props = { requestClose: () => void }

export type NotificationProps = NotificationBody & Props

export const Notification: FC<NotificationProps> = (props) => {
  return (
    // The animation can be paused on hover. Once it's finished, request to close the notification
    <div
      onAnimationEnd={props.requestClose}
      data-test-id={`${props.variant}-notification`}
      className={cn(
        'pause-on-hover pointer-events-auto relative w-full min-w-[300px] select-none border border-t-0 border-shade-200 bg-white p-4 pb-[calc(1rem+env(safe-area-inset-bottom))] shadow-notification',
        'sm:w-auto sm:p-6',
        // 'hoverAndPointerScreens:hover:after:plays',
        'before:absolute before:left-0 before:top-0 before:h-0.5 before:w-full before:bg-shade-200',
        'after:absolute after:left-0 after:top-0 after:h-0.5 after:animate-fill-horizontally-slow',
        props.variant === 'error' ? 'after:bg-error' : 'after:bg-bigStoneLight',
      )}
    >
      {['image-action', 'image-action-undo', 'icon-action', 'icon-action-undo'].includes(
        props.variant,
      ) && (
        <Action
          {...(props as ActionProps)}
          // This ensures that the notification is closed once a button is clicked
          onClickAction={() => {
            ;(props as ActionProps).onClickAction()
            props.requestClose()
          }}
        />
      )}

      {props.variant === 'confirmation' && (
        <Confirmation
          {...(props as ConfirmationProps)}
          onClickAction={() => {
            ;(props as ConfirmationProps).onClickAction()
            props.requestClose()
          }}
        />
      )}

      {props.variant === 'simple-confirmation' && <Simple {...(props as SimpleProps)} />}
      {props.variant === 'error' && <Error {...(props as ErrorProps)} />}

      {props.variant === 'language-switch' && (
        <LanguageSwitch
          {...(props as LanguageSwitchProps)}
          onClickAction={() => {
            ;(props as LanguageSwitchProps).onClickAction()
            props.requestClose()
          }}
        />
      )}
    </div>
  )
}
