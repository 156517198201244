import { FC } from 'react';
import { parse } from '@config/theme';
import { LinariaText, LinariaTextProps } from '@components/gassan-ui/Typography/LinariaText';
type MenuLabelProps = {
  variant?: LinariaTextProps['variant'];
  as?: LinariaTextProps['as'];
};
const label = parse({
  display: 'flex',
  alignItems: 'center'
}, "le7wuez");
export const MenuLabel: FC<MenuLabelProps> = ({
  variant = 'small',
  as = 'div',
  children
}) => {
  return <LinariaText className={label} variant={variant} as={as}>
      {children}
    </LinariaText>;
};

require("./MenuLabel.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./MenuLabel.tsx");