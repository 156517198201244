// @ts-strict-ignore
import React, { FC, InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { MarginBottomProps, MarginTopProps } from 'styled-system'
import { Flex } from '../Layout'
import { bodyRegularStyle } from '../Typography/styles'

const Label = styled.label`
  ${bodyRegularStyle}
  transition: 0.2s ease-in-out all;
  font-size: 0.875rem;
  display: block;
  color: ${(p) => p.theme.colors.shades[700]};
  padding: 0.25rem 1rem 0.25rem 1rem;

  &::before {
    transition: 0.2s ease-in-out all;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    background: rgba(255, 255, 255.6);
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid ${(p) => p.theme.colors.shades[400]};
    border-radius: 0.625rem;
  }

  &::after {
    width: 0.7rem;
    height: 0.7rem;
    position: absolute;
    background-color: ${(p) => p.theme.colors.tabaccoBrown};
    content: '';
    opacity: 0;
    left: 10px;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.35rem;
  }
`

const Input = styled.input`
  opacity: 0;
  ${(p) =>
    p.disabled
      ? // Disabled state
        css`
          + ${Label} {
            cursor: not-allowed;
            color: ${p.theme.colors.shades[500]};
          }
          + ${Label}::before {
            border-color: ${p.theme.colors.shades[400]};
            background-color: ${p.theme.colors.shades[100]};
          }
        `
      : // Default state
        css`
          &:hover {
            + ${Label} {
              cursor: pointer;
              color: ${(p) => p.theme.colors.shades[800]};
            }
            + ${Label}::before {
              border-color: ${p.theme.colors.shades[500]};
            }
          }
          &:focus {
            + ${Label}::before {
              box-shadow: 0 0 0 3px ${(p) => p.theme.colors.tabaccoLight};
            }
          }
        `};

  &:checked {
    + ${Label}::before {
      border-color: ${(p) => p.theme.colors.tabaccoBrown};
    }
    + ${Label}::after {
      opacity: 1;
    }
  }
`

export type RadioProps = InputHTMLAttributes<HTMLInputElement> &
  MarginBottomProps &
  MarginTopProps & {
    label: React.ReactNode
    id: string
  }

export const Radio: FC<RadioProps> = ({ label, mb = '1.5rem', mt = '0', ...rest }) => {
  const id =
    rest.id ||
    rest.name ||
    `id-${[...Array(5)].map((i) => (~~(Math.random() * 36)).toString(36)).join('')}`
  return (
    <Flex position="relative" mb={mb} mt={mt}>
      <Input type="radio" data-field-id={rest.name} id={id} {...rest}></Input>
      <Label htmlFor={id}>{label}</Label>
    </Flex>
  )
}
