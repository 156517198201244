import React, { FC, useContext, useEffect, useState } from 'react'
import { Provider } from 'urql'
import { createUrqlClient } from '@lib/urqlClient'

// Custom Provider around Urql's client, to allow a resetClient method
// https://github.com/FormidableLabs/urql/issues/297

const UrqlClientContext = React.createContext({
  resetClient: () => {},
})
type Props = {
  lang: string
}

const UrqlClientProvider: FC<Props> = ({ lang = 'nl', children }) => {
  const [client, setClient] = useState(createUrqlClient(lang))
  const resetClient = () => {
    const client = createUrqlClient(lang, true)
    setClient(client)
  }

  useEffect(() => {
    const client = createUrqlClient(lang, true)
    setClient(client)
  }, [lang])

  return (
    <UrqlClientContext.Provider
      value={{
        resetClient,
      }}
    >
      <Provider value={client}>{children}</Provider>
    </UrqlClientContext.Provider>
  )
}

export const useResetClient = () => useContext(UrqlClientContext)

export default UrqlClientProvider
