// @ts-strict-ignore
import { CartDocument, CartQuery, CartQueryVariables } from '@generated'
import { FC, useEffect } from 'react'
import { useClient } from 'urql'
import create from 'zustand'
import { useAuth } from '@lib/hooks/use-auth'

type CartReferenceStore = {
  reference: string | null
  setReference: (reference: string) => void
}
export const useCartReferenceStore = create<CartReferenceStore>((set) => ({
  reference: null,
  setReference: (reference: string) => set({ reference }),
}))

type LoadCartProps = {}

export const LoadCart: FC<LoadCartProps> = () => {
  const cartReferenceStore = useCartReferenceStore()
  const { isLoggedIn, isLoading } = useAuth()
  const client = useClient()
  useEffect(() => {
    if (isLoading) {
      return
    }
    async function prefetchCart(reference?: string) {
      const cart = await client
        .query<CartQuery, CartQueryVariables>(CartDocument, { reference })
        .toPromise()
      cartReferenceStore.setReference(cart.data.cart.id)
      if (!isLoggedIn) {
        window.localStorage.setItem('cart_reference', cart.data.cart.id)
      } else {
        window.localStorage.removeItem('cart_reference')
      }
    }
    if (isLoggedIn) {
      prefetchCart()
    } else {
      prefetchCart(window.localStorage.getItem('cart_reference') || undefined)
    }
  }, [isLoggedIn, isLoading]) // eslint-disable-line
  return null
}
