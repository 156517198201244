import { useTranslation } from 'next-i18next'
import { FC, useEffect } from 'react'
import { useThirdPartyScriptStore } from '@lib/hooks/useThirdPartyScript'

const RolexClock: FC = () => {
  const {
    i18n: { language },
  } = useTranslation()
  const { rolexScriptIsLoaded } = useThirdPartyScriptStore()

  useEffect(() => {
    if (rolexScriptIsLoaded) {
      //@ts-ignore
      const rdp = new RolexRetailerClock()
      const rdpCondfig = {
        dealerAPIKey: '3749a28a87220925506fa122a876c80e',
        lang: language === 'nl' ? 'nl' : 'en',
        colour: 'gold',
      }
      try {
        rdp.getRetailerClock(rdpCondfig)
      } catch (err) {}
    }
  }, [rolexScriptIsLoaded]) //eslint-disable-line

  return <div className="rolex-retailer-clock h-[70px] w-[150px]" />
}

export default RolexClock
