import Portal from '@reach/portal'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { LinariaText } from '@components/gassan-ui/Typography/LinariaText'
import * as styles from './styles'

export const ExitPreview: FC = () => {
  const { isPreview } = useRouter()
  return (
    <>
      {isPreview && (
        <Portal>
          <a href="/api/exit-preview" className={styles.buttonContainer}>
            <LinariaText variant="micro" mb="0">
              Exit preview
            </LinariaText>
          </a>
        </Portal>
      )}
    </>
  )
}
