import { FC } from 'react'
import Blocks from './Blocks'
import Columns from './Columns'
import MadeInAmsterdam from './MadeInAmsterdam'
import SubFooter from './SubFooter'

interface Props {
  disableAwin?: boolean
}

const Footer: FC<Props> = ({ disableAwin = false }) => {
  return (
    <>
      <footer className="mt-12 sm:mt-14 md:mt-20 lg:mt-36">
        <Blocks />
        <Columns />
        <SubFooter />
        <MadeInAmsterdam />
      </footer>
      {/* //
      // AWIN MASTER TAG
      // https://wiki.awin.com/index.php/Advertiser_Tracking_Guide#Journey_Tag_.2F_Mastertag
      //
      // Default loading of Master tag is disabled on Confirmed page. See that component for more details.
       */}
      {!disableAwin && <script defer type="text/javascript" src="https://www.dwin1.com/8211.js" />}
    </>
  )
}

export default Footer
