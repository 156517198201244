import React, { FC } from 'react'
import { GridColumnProps, MarginBottomProps, MarginTopProps } from 'styled-system'
import { Box } from '../Layout'
import { Text } from '../Typography'
import { AnimatedLabel } from './AnimatedLabel'

export type FieldControlProps = MarginBottomProps &
  MarginTopProps &
  GridColumnProps & {
    label?: React.ReactNode
    field: string
    assistiveText?: string
    errorMsg?: string
    htmlFor?: string
  }

export const FieldControl: FC<FieldControlProps> = ({
  label,
  assistiveText,
  errorMsg,
  children,
  field,
  mb = '1.5rem',
  htmlFor,
  ...rest
}) => {
  return (
    <Box {...rest} mb={mb}>
      {['input', 'textarea'].includes(field) ? (
        <Box position="relative">
          {children}
          {label && <AnimatedLabel htmlFor={htmlFor}>{label}</AnimatedLabel>}
        </Box>
      ) : (
        children
      )}
      {errorMsg ? (
        <Text as="div" variant="micro" mt=".5rem" mb={0} color="error">
          {errorMsg}
        </Text>
      ) : assistiveText ? (
        <Text
          as="div"
          variant="micro"
          mt=".5rem"
          mb={0}
          color="shades.500"
          pl={['checkbox', 'radio'].includes(field) ? '2rem' : 0}
        >
          {assistiveText}
        </Text>
      ) : null}
    </Box>
  )
}

FieldControl.defaultProps = {
  mb: 3,
}
