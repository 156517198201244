import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { getUrlSlugInLocale } from '@lib/get-url-slug-in-locale'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Box, Button } from '@gassan-ui'

type ButtonsProps = {
  handleClickContact: () => void
}

const Buttons: FC<ButtonsProps> = ({ handleClickContact }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('other')
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0, transition: { delay: 0.15, duration: 0.25 } }}
      exit={{ opacity: 0, y: 50 }}
    >
      <Box
        width="100%"
        p="1rem"
        bg="pampasLight"
        mt="1rem"
        boxShadow="0px 12px 20px rgba(0, 0, 0, 0.09)"
        style={{ pointerEvents: 'all' }}
      >
        <Button
          onClick={handleClickContact}
          variant="outline"
          className="mb-4 min-w-full"
          as="link"
          style={{ background: 'transparent' }}
        >
          Contact
        </Button>
        <LocalizedLink href={`/${getUrlSlugInLocale('faqPage', language)}`}>
          <Button
            variant="outline"
            className="mb-4 min-w-full"
            as="link"
            style={{ background: 'transparent' }}
          >
            {t('faq')}
          </Button>
        </LocalizedLink>
      </Box>
    </motion.div>
  )
}

export default Buttons
