import React, { FC } from 'react'
import { cn } from '@lib/cn'

type NotificationImageProps = React.ImgHTMLAttributes<HTMLImageElement>

export const NotificationImage: FC<NotificationImageProps> = ({
  alt = '',
  className,
  ...props
}) => {
  return (
    <img
      alt={alt}
      className={cn('h-10 w-10 shrink-0 bg-shade-50 object-contain sm:h-14 sm:w-14', className)}
      {...props}
    />
  )
}
