// @ts-strict-ignore
import { CartFragmentFragment } from '@generated'
import { typedWindow } from './types'

type CartItems = {
  item: string
  price: number
  quantity: number
}[]

export function setCart(cart: null | CartFragmentFragment) {
  const items: CartItems =
    cart && cart.items
      ? cart.items.map((item) => ({
          item: item.articleId,
          price: item.totalPrice / 100,
          quantity: item.quantity,
        }))
      : []

  if (typedWindow.ScarabQueue) {
    typedWindow.ScarabQueue.push(['cart', items])
  }
}
