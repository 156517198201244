import { useRouter } from 'next/router'
import { MutableRefObject, useEffect, useState } from 'react'
import { useBodyScrollLock } from '@lib/hooks/useBodyScrollLock'
import { NavActiveOptions } from '../HeaderContent'

type UseHeaderContentProps = {
  ref: MutableRefObject<HTMLElement>
}

export function useHeaderContent({ ref }: UseHeaderContentProps) {
  const router = useRouter()
  const [active, setActive] = useState<NavActiveOptions>(null)
  const menuIsActive = active !== null
  // Scroll lock is set in the Search component self
  const hasBodyScrollLock = active !== null && active !== 'search'
  useBodyScrollLock(ref, hasBodyScrollLock)

  useEffect(() => {
    // Close the dropdown when the current route changes
    const handleRouteChangeStart = () => {
      setActive(null)
    }
    router.events.on('routeChangeStart', handleRouteChangeStart)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, []) // eslint-disable-line

  function handleSetActive(key: NavActiveOptions) {
    // If key === null or key is the same as the current
    if (key === null || key === active) {
      setActive(null)
    } else {
      setActive(key)
    }
  }

  return { active, handleSetActive, menuIsActive }
}
