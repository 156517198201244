import React, { FC } from 'react'
import { cn } from '@lib/cn'
import { TextProps } from '@components/gassan-ui'
import { Text } from '../Typography'

export const NotificationLabel: FC<TextProps> = ({ className, ...props }) => {
  return (
    <Text
      className={cn('block w-full overflow-hidden text-ellipsis whitespace-nowrap', className)}
      as="div"
      mb="0"
      {...props}
    />
  )
}
