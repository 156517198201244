import { createThemeSystem } from 'theme-system'
import { theme } from '@components/gassan-ui/theme'

export const linariaTheme = {
  ...theme,
  // value of theme.colors and theme.breakpoints type doesn't match the type required by linaria...
  // ...so we manually add colors here
  colors: {
    bigStone: '#182F40',
    bigStoneLight: '#2D4353',
    oldLace: '#FCF0E4',
    oldLaceDark: '#F5E3D0',
    pampas: '#F0EBE8',
    pampasLight: '#F7F5F3',
    eagle: '#B2B1A5',
    tabaccoBrown: '#785D42',
    tabaccoLight: '#D7CEC6',
    error: '#CB3333',
    errorLight: '#EFC2C2',
    success: '#4CAF50',
    white: '#ffffff',
    shade50: '#FAFAFA',
    shade100: '#F7F7F7',
    shade200: '#F0F0F0',
    shade300: '#D9D9D9',
    shade400: '#BDBDBD',
    shade500: '#909090',
    shade600: '#545454',
    shade700: '#2E2E2E',
    shade800: '#141414',
    rolexPrimary: '#127749',
    rolexSecondary: '#cbb484',
    rolexRed: '#c00',
    rolexShade100: '#f6f6f6',
    rolexShade200: '#f0f0f0',
    rolexShade300: '#e6e6e6',
    rolexShade500: '#a0a0a0',
    rolexShade700: '#767676',
    rolexShade900: '#212121',
    tudorRed: '#be0100',
    tudorText: '#151515',
    tudorShade100: '#f5f5f5',
    tudorShade800: '#141416',
    inherit: 'inherit',
  },
  // ...and import the breakpoints
  breakpoints: {
    small: '40em', // 640px
    medium: '48em', // 768px
    large: '64em', // 1024px
    xlarge: '90em', // 1440px
    xxlarge: '97em', // 1552px
    huge: '120em', // 1920px,
  },
  // ...add the same fontFamilies in the way Linaria likes them
  fontFamilies: {
    heading: 'Louize Display, serif',
    body: 'Graphik, Arial, sans-serif',
    specials: 'Artegra Sans Extended, Arial, sans-serif',
    quote: 'Quentin, serif',
  },
  // necessary Linaria keys, the requirement of these keys could be removed maybe
  fontSizes: {},
  fontWeights: {},
  space: {
    '0': 0,
    '1': '.25rem',
    '2': '.5rem',
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '13': '3.25rem',
    '14': '3.5rem',
    '15': '3.75rem',
    '16': '4rem',
    '17': '4.25rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    auto: 'auto',
  },
}

export type Theme = typeof linariaTheme

export const { parse, parseAll, utilities } = createThemeSystem<Theme>(linariaTheme)
