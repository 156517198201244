import styled from 'styled-components'
import { breakpoints } from '../theme'

export const AnimatedLabel = styled.label`
  font-family: ${(p) => p.theme.fonts.body};
  position: absolute;
  top: 0;
  pointer-events: none;
  font-size: 1rem;
  padding-left: 0.75rem;
  transition: transform 0.1s ease-out;
  @media screen and (min-width: ${breakpoints.small}) {
    padding-left: 1rem;
  }
`
