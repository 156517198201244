import { cx } from '@linaria/core';
import { FC } from 'react';
import { subItemGrid } from '@components/Page/Header/shop-menu/styles';
type HideSubItemShopProps = {
  isVisible: boolean;
};
const container = "c8wjwnq";
export const HideSubItemShop: FC<HideSubItemShopProps> = ({
  children,
  isVisible
}) => {
  return <div className={cx(container, subItemGrid)} data-is-visible={isVisible}>
      {children}
    </div>;
};

require("./HideSubItemShop.linaria.module.css!=!../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./HideSubItemShop.tsx");