import { useEffect, useRef, useState } from 'react'
import { useScrollDirection } from '@lib/use-scroll-direction'
import { Variant, Visibility } from '..'

type UseHeaderProps = {
  topInView: boolean
  headerInView: boolean
  initialVariant: Variant
  canHeaderShow?: boolean
}

export function useHeader({
  topInView,
  headerInView,
  initialVariant,
  canHeaderShow,
}: UseHeaderProps) {
  const scrollDirection = useScrollDirection()
  const [variant, setVariant] = useState<Variant>(initialVariant)
  const [visibility, setVisibility] = useState<Visibility>(null)
  const prevVisibility = useRef<Visibility>(initialVariant === 'fixed' ? 'hidden' : null)

  useEffect(() => {
    // In this variant, the header is hidden initially and can only be shown on scroll up
    // and if canHeaderShow === true
    if (initialVariant === 'fixed') {
      if (scrollDirection === 'down' || !canHeaderShow) {
        setVisibility('hidden')
      }
      if (canHeaderShow && scrollDirection === 'up') {
        setVisibility('visible')
      }
    }

    // InitialMode absolute is most used (and is the default), it start as a absolute header on top of the page
    // And transitions to a fixed-hidden variant on scroll down & fixed-visible variant on scroll up
    // HeaderHeightRef is added to ensure that the content below the header is pushed down to the height of the header
    if (initialVariant === 'absolute') {
      if (variant === 'fixed' && topInView) {
        setVariant('absolute')
        setVisibility(null)
        prevVisibility.current = null
      }
      if (!headerInView && prevVisibility.current === null) {
        setVariant('fixed')
        setVisibility(null)
        prevVisibility.current = null
      }
      if (!headerInView && prevVisibility.current === 'visible') {
        setVariant('fixed')
        setVisibility('hidden')
        prevVisibility.current = 'hidden'
      }
      if (scrollDirection === 'up' && !headerInView) {
        setVariant('fixed')
        setVisibility('visible')
        prevVisibility.current = 'visible'
      }
    }
  }, [topInView, headerInView, scrollDirection, initialVariant, variant, canHeaderShow])

  return { visibility, variant }
}
