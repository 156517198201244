// @ts-strict-ignore
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Icon, breakpoints } from '@gassan-ui'

const Container = styled(Box)`
  transition: border 0.2s ease;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.shades[800]};
  margin-top: 0.5rem;
  @media screen and (min-width: ${breakpoints.large}) {
    border-bottom-width: 2px;
  }
`

const Input = styled.input`
  top: 0;
  width: calc(100% - 3rem);
  height: 3.5rem;
  line-height: 3.5rem;
  left: 0;
  background: red;
  font-family: ${(p) => p.theme.fonts.heading};
  color: ${(p) => p.theme.colors.shades[800]};

  letter-spacing: 0.03em;
  line-height: 1.3;
  font-size: 1.25rem;
  outline: 0;
  background: none;
  border: none;

  &::placeholder {
    color: ${(p) => p.theme.colors.shades[400]};
  }

  @media screen and (min-width: ${breakpoints.small}) {
    width: calc(100% - 4rem);
    font-size: 2rem;
    height: 4rem;
    line-height: 4rem;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    width: calc(100% - 5rem);
    font-size: 2.125rem;
  }
  @media screen and (min-width: ${breakpoints.xlarge}) {
    font-size: 2.5rem;
    height: 5rem;
    line-height: 5rem;
  }
`

const Form = styled.form`
  width: 100%;
`

type Props = {
  onChange: (value: string) => void
  placeholder: string
}

let timer

const SearchInput: FC<Props> = ({ onChange, placeholder }) => {
  const [value, setValue] = useState('')
  const [query, setQuery] = useState('')
  const {
    t,
    i18n: { language },
  } = useTranslation('other')
  const router = useRouter()
  useEffect(() => {
    clearTimeout(timer)
    timer = setTimeout(() => setQuery(value), 100)
    return () => {
      clearTimeout(timer)
    }
  }, [value])

  useEffect(() => onChange(query), [query]) // eslint-disable-line

  const handleSubmit = (e) => {
    e.preventDefault()
    router.push({
      pathname: `/${language}/${t('search').toLowerCase()}`,
      query: {
        s: query,
      },
    })
  }
  return (
    <Container>
      <Icon
        icon="search"
        size={['2.5rem', '2.5rem', '3.5rem', '4rem']}
        ml={['-.5rem', '-.5rem', '-.75rem', '-1rem']}
      />
      <Form onSubmit={handleSubmit}>
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          autoFocus
        />
      </Form>
    </Container>
  )
}

export default SearchInput
