import { FC } from 'react'
import { Box, Span } from '@gassan-ui'

type Props = {}

const MadeInAmsterdam: FC<Props> = () => {
  return (
    <Box
      height="8rem"
      bg={['white', 'white', 'white', 'pampas']}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <img src="/logos/amsterdam.svg" alt="" />
      <Span fontFamily="heading" fontSize="1rem" mt=".5rem" color="shades.800">
        Made with care in Amsterdam
      </Span>
    </Box>
  )
}

export default MadeInAmsterdam
