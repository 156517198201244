import Link from 'next/link'
import React, { FC } from 'react'
import { LinariaText } from '@components/gassan-ui/Typography/LinariaText'
import { ListLink } from '../utils/types'
import * as styles from './styles'

type HeaderLinkColumnProps = {
  title: string
  readMoreLink?: React.ReactNode
  links: ListLink[]
  linkSpace: 'large' | 'regular'
}

export const HeaderLinkColumn: FC<HeaderLinkColumnProps> = ({
  title,
  readMoreLink,
  links,
  linkSpace,
}) => {
  return (
    <div>
      <LinariaText variant="small" color="shade800" mb="4">
        {title}
      </LinariaText>
      <ul>
        {links.map((item, key) => {
          const itemUrl = item.url.startsWith('/') ? item.url : `/${item.url}`
          return (
            <li key={key}>
              <Link href={itemUrl} passHref>
                <a className={styles.link} data-space={linkSpace}>
                  {item.label}
                </a>
              </Link>
            </li>
          )
        })}
      </ul>
      {readMoreLink && readMoreLink}
    </div>
  )
}
