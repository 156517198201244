import { CartFragmentFragment } from '@generated'
import { go } from './helpers/go'
import { setCart } from './helpers/set-cart'
import { setCustomerId } from './helpers/set-customer-id'

type DefaultEmarsysTracking = {
  customerId?: number
  cart: null | CartFragmentFragment
}

export function defaultEmarsysTracking({ customerId, cart }: DefaultEmarsysTracking) {
  if (customerId) {
    setCustomerId(customerId)
  }
  setCart(cart)
  go()
}
