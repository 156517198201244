import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { useNotifications } from '@components/NotificationsProvider'
import { imgix } from '../imigx'
import { useAuth } from './use-auth'
import { useMembershipModal } from './use-membership-modal'
import { useWishlistData } from './use-wishlist-data'

export type ToggleProps = {
  id: string
  title: string
  subtitle: string
  image: string
}

export function useWishlist() {
  const { setShowMembershipModalVariant } = useMembershipModal()
  const { addNotification } = useNotifications()
  const { isLoggedIn } = useAuth()
  const { wishlist, updateWishlist } = useWishlistData()
  const { push } = useRouter()
  const { t } = useTranslation(['other', 'navigation'])

  const articleIds = useMemo(() => wishlist?.articleIds ?? [], [wishlist])

  const toggle = useCallback(
    (props: ToggleProps) => {
      // Toggle the global membership modal if the user is not logged in and return early
      if (!isLoggedIn) {
        setShowMembershipModalVariant('wishlist')
        return
      }

      // Toggle the product id in the wishlist
      if (articleIds.includes(props.id)) {
        removeProductId(props)
      } else {
        addProductId(props)
      }

      function addProductId(props: ToggleProps) {
        // Get the current wishlist product ids and remove the current product id
        const ids = [props.id, ...articleIds.filter((id) => id !== props.id)]
        // This is anync, but we don't need to wait for it to finish
        // We just want to call it as early as possible
        updateWishlist(ids)

        addNotification({
          variant: 'image-action',
          label: props.title,
          sublabel: props.subtitle,
          image: imgix(props.image, { width: 180 }),
          description: t('addedToWishlist', { ns: 'other' }),
          actionLabel: t('view', { ns: 'other' }),
          onClickAction: () => push(t('account.wishlist.url', { ns: 'navigation' })),
        })
      }

      function removeProductId(props: ToggleProps) {
        // Get the current wishlist product ids and remove the current product id
        const ids = articleIds.filter((item) => item !== props.id)
        // This is anync, but we don't need to wait for it to finish
        // We just want to call it as early as possible
        updateWishlist(ids)

        addNotification({
          variant: 'image-action',
          label: props.title,
          sublabel: props.subtitle,
          image: imgix(props.image, { width: 180 }),
          description: t('removedFromWishlist', { ns: 'other' }),
          actionLabel: t('undo', { ns: 'other' }),
          onClickAction: () => {
            setTimeout(() => {
              addProductId(props)
            }, 100)
          },
        })
      }
    },

    [
      isLoggedIn,
      articleIds,
      setShowMembershipModalVariant,
      addNotification,
      push,
      t,
      updateWishlist,
    ],
  )

  return {
    wishlist,
    articleIds: articleIds,
    toggle,
  }
}
