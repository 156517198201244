// @ts-strict-ignore
import { useTranslation } from 'next-i18next'
import React, { FC, useContext, useEffect, useState } from 'react'
import { getLocalizedInitialValues } from './helpers/get-localized-initial-values'
import { initialValues } from './initialValues'
import { Giftcard, OrderFormValues } from './types'

type State = {
  orderId: number | null
  order: OrderFormValues | null
  giftcards: Giftcard[] | null
  paymentMethod: string
  paymentAction: string
  country: string | null
}
type Methods = {
  setOrder: (order: OrderFormValues) => void
  setOrderId: (orderId: number) => void
  addGiftcard: (giftcard: Giftcard) => void
  setGiftcards: (giftcards: Giftcard[]) => void
  removeGiftcard: (giftcardNumber: string) => void
  resetGiftcards: () => void
  setPaymentMethod: (paymentMethod) => void
  setPaymentAction: (paymentAction) => void
  clear: () => void
  clearOrderId: () => void
  setCountry: (country: string) => void
}

const initialState: State = {
  orderId: null,
  order: initialValues,
  giftcards: [],
  paymentMethod: null,
  paymentAction: null,
  country: null,
}

const CheckoutContext = React.createContext<State & Methods>({
  ...initialState,
  setOrder: () => {},
  setOrderId: () => {},
  addGiftcard: () => {},
  setGiftcards: () => {},
  removeGiftcard: () => {},
  resetGiftcards: () => {},
  setPaymentMethod: () => {},
  setPaymentAction: () => {},
  clear: () => {},
  clearOrderId: () => {},
  setCountry: () => {},
})

type Props = {
  initalOrderData?: OrderFormValues
}

const CheckoutProvider: FC<Props> = ({ children, initalOrderData = null }) => {
  const {
    i18n: { language },
  } = useTranslation()
  const localizedInitialValues = getLocalizedInitialValues(language)
  const [state, setState] = useState<State>({
    orderId: null,
    order: localizedInitialValues,
    giftcards: [],
    paymentMethod: null,
    paymentAction: null,
    country: null,
  })
  const setOrder = (order: OrderFormValues) => {
    window.sessionStorage.setItem(
      'order',
      JSON.stringify({ ...order, acceptTermsAndConditions: false }),
    )
    setState((state) => ({ ...state, order }))
  }
  const setOrderId = (orderId: number) => {
    window.sessionStorage.setItem('order_id', orderId.toString())
    setState((state) => ({ ...state, orderId }))
  }
  const addGiftcard = (giftcard: Giftcard) =>
    setState((state) => ({ ...state, giftcards: [...state.giftcards, giftcard] }))
  const setGiftcards = (giftcards: Giftcard[]) => setState((state) => ({ ...state, giftcards }))
  const removeGiftcard = (giftcardNumber: string) =>
    setState((state) => ({
      ...state,
      giftcards: state.giftcards.filter((card) => card.giftcardNumber !== giftcardNumber),
    }))
  const resetGiftcards = () =>
    setState((state) => ({
      ...state,
      giftcards: [],
    }))
  const setPaymentMethod = (paymentMethod) => setState((state) => ({ ...state, paymentMethod }))
  const setPaymentAction = (paymentAction) => {
    setState({ ...state, paymentAction })
  }
  const clear = () => setState(initialState)
  const clearOrderId = () => {
    window.sessionStorage.removeItem('order_id')
    setState((state) => ({ ...state, orderId: null }))
  }
  const setCountry = (country) => setState({ ...state, country })

  useEffect(() => {
    const order = window.sessionStorage.getItem('order')
    const orderId = window.sessionStorage.getItem('order_id')

    if (order) {
      setState((state) => ({ ...state, order: JSON.parse(order) }))
    }

    if (orderId) {
      setState((state) => ({ ...state, orderId: parseInt(orderId, 10) }))
    }
  }, [])

  return (
    <CheckoutContext.Provider
      value={{
        ...state,
        setOrder,
        setOrderId,
        addGiftcard,
        setGiftcards,
        removeGiftcard,
        resetGiftcards,
        setPaymentMethod,
        setPaymentAction,
        clear,
        clearOrderId,
        setCountry,
      }}
    >
      <>{children}</>
    </CheckoutContext.Provider>
  )
}

export function useCheckout() {
  const ctx = useContext(CheckoutContext)
  if (typeof ctx === 'undefined') {
    throw new Error('Can only use checkout context within a CheckoutContext Provider')
  }
  return ctx
}

export default CheckoutProvider
