import { rem, rgba } from 'polished'
import styled, { css } from 'styled-components'
import { MaxWidthProps, maxWidth, space } from 'styled-system'
import { Image as BaseImage } from '../Image'
import { Span } from '../Typography'
import { linkStyle } from '../Typography/styles'
import { breakpoints } from '../theme'

export const Container = styled.div<
  MaxWidthProps & { ratio: number[]; isPlaying: boolean; isFixedRatio: boolean }
>`
  ${maxWidth};
  ${space};
  background: ${(p) => p.theme.colors.shades[200]};
  width: 100%;
  position: relative;
  &:before {
    padding-top: ${(p) =>
      p.isFixedRatio ? p.ratio[0] * 100 : p.isPlaying ? '56.25' : p.ratio[0] * 100}%;
    content: '';
    display: block;
    width: 100%;
    ${(p) =>
      p.ratio.length > 1 &&
      p.ratio.slice(1).map(
        (ratio, index) => css`
          @media screen and (min-width: ${p.theme.breakpoints[index + 1]}) {
            padding-top: ${p.isPlaying ? '56.25' : ratio * 100}%;
          }
        `,
      )}
  }
`

export const Video = styled.video<{ status: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${(p) => (p.status === 'idle' ? 0 : 1)};
  outline: 0;
`

export const Image = styled(BaseImage)<{ status: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;

  transition: opacity 0.25s ease-in-out;
  ${(p) =>
    p.status === 'playing' &&
    css`
      pointer-events: none;
      opacity: 0;
    `}
`

export const AudioButtonBackground = styled.span`
  position: absolute;
  display: block;
  width: 100%;
  height: 20%;
  left: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);

  @media screen and (min-width: ${breakpoints.small}) {
    height: 10%;
  }
`
export const ToggleAudioButton = styled.button`
  position: absolute;
  z-index: 4;
  padding: 0.75rem 1rem;
  bottom: 0;
  left: 0;
`

export const PlayButton = styled.button<{ status: string }>`
  position: relative;
  transition: background 0.25s ease-in-out;
  padding: 2rem;
  width: ${rem(70)};
  height: ${rem(70)};
  border-radius: ${rem(35)};
  color: ${(p) => p.theme.colors.oldLace};
  background: ${(p) => rgba(p.theme.colors.oldLace, 0.2)};

  outline: 0;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${(p) =>
    p.status !== 'loading' &&
    css`
      &:hover,
      &:focus {
        background: ${(p) => rgba(p.theme.colors.oldLace, 0.4)};
      }
    `};
  ${(p) =>
    p.status === 'loading' &&
    css`
      cursor: wait;
    `};
`

export const Content = styled.div<{ status: string }>`
  transition: opacity 0.25s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: ${(p) => (p.status === 'playing' ? 0 : 1)};
`

export const Text = styled(Span)`
  ${linkStyle};
  display: block;
  margin-top: 1.25rem;
  color: ${(p) => p.theme.colors.oldLace};
`
