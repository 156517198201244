import { cx } from '@linaria/core';
import { FC } from 'react';
import { parse } from '@config/theme';
import { HouseOfGassanContent } from '@lib/kontent/navigation-helpers/get-house-of-gassan-content';
import { hideMenuElement } from '@components/Page/Header/styles';
import { Container } from '@components/gassan-ui';
import { HeaderImageColumn } from './image-column';
type HeaderHouseOfGassanMenuProps = {
  columns: HouseOfGassanContent;
  isVisible: boolean;
};
export const HeaderHouseOfGassanMenu: FC<HeaderHouseOfGassanMenuProps> = ({
  columns,
  isVisible
}) => {
  return <Container className={cx(grid, hideMenuElement)} data-is-visible={isVisible}>
      {columns.map((column, key) => <HeaderImageColumn {...column} key={key} showOnlyOnXlarge={key === columns.length - 1} />)}
    </Container>;
};
const grid = parse({
  display: 'grid'
}, "g1xudumz");

require("./HouseOfGassanMenu.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./HouseOfGassanMenu.tsx");