import { useTranslation } from 'next-i18next'
import Link, { LinkProps } from 'next/link'
import React, { FC } from 'react'
import { getLocalePrefix } from '@lib/get-locale-prefix'

type LocalizedLinkProps = Omit<LinkProps, 'as'>

const LocalizedLink: FC<LocalizedLinkProps> = ({ href, children, ...props }) => {
  const {
    i18n: { language },
  } = useTranslation()
  let localizedHref =
    typeof href === 'string'
      ? `${getLocalePrefix(language)}${href}`
      : { ...href, pathname: `${getLocalePrefix(language)}${href.pathname}` }

  // We need rolex as a brand page (for navigation, category links, etc), aswell as the regular homepage (e.g. /nl/rolex)
  // We've fixed this by adding a rolex brand page with url slug rolex-horloges & rolex-watches
  // Here we replace every reference to those url slugs with just /rolex so that we always like to the actual rolex homepage
  if (href === '/rolex-horloges') {
    localizedHref = '/nl/rolex'
  }
  if (href === '/rolex-watches') {
    localizedHref = '/en/rolex'
  }

  if (children) {
    return (
      <Link {...props} href={localizedHref} passHref>
        {React.cloneElement(children as any, {
          href: href,
        })}
      </Link>
    )
  }

  return (
    <Link {...props} href={localizedHref} passHref>
      {children}
    </Link>
  )
}

export default LocalizedLink
