import {
  BackgroundProps,
  BorderProps,
  ColorProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TypographyProps,
  background,
  border,
  color,
  compose,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system'
import { BaseProps } from '../theme'

export type StyledTextProps = ColorProps &
  SpaceProps &
  TypographyProps &
  LayoutProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  BaseProps

export const allStyledSystemFunctions = compose(
  color,
  space,
  typography,
  layout,
  background,
  border,
  position,
  shadow,
)
