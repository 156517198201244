// @ts-strict-ignore
import Cookies from '@components/Cookies'
import Debug from '@components/Debug'
import { Head } from '@components/Head'
import { LoadCart } from '@components/LoadCart'
import LocalizationProvider from '@components/Localization'
import { Messages } from '@components/Localization/messages/types'
import { NotificationsProvider } from '@components/NotificationsProvider'
import { Scripts } from '@components/Scripts'
import TopLoadingBar from '@components/TopLoadingBar'
import UrqlClientProvider from '@components/UrqlClientProvider'
import CheckoutProvider from '@components/_checkout/CheckoutProvider'
import { ExitPreview } from '@components/exit-preview'
import { Fonts } from '@components/gassan-ui/theme/Fonts'
import { MembershipModal } from '@components/membership-modal'
import Tracking from '@components/tracking'
import { theme } from '@gassan-ui'
import { initSentry } from '@lib/sentry'
import ErrorPage from '@pages/_error'
import { resetIdCounter } from 'downshift'
import { LazyMotion, domAnimation } from 'framer-motion'
import { NextComponentType, NextPageContext } from 'next'
import { appWithTranslation } from 'next-i18next'
import { AppProps } from 'next/app'
import { AppTreeType } from 'next/dist/shared/lib/utils'
import { Router, useRouter } from 'next/router'
import { ThemeProvider } from 'styled-components'
import { Client } from 'urql'
import '../styles/utilities.css'

// this should be imported after utilities.css to make sure the
// tailwind classes are not overwritten by theme-system classes
import Awin from '@components/Awin'
import PinterestTracking from '@components/tracking/PinterestTracking'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { DefaultSeo } from 'next-seo'
import '../styles/global.css'

type Props = {
  err?: Error
  lang: null | string
  messages: Messages
  client: Client
}

export type CustomNextPageContext = NextPageContext & {
  lang: string
}

export type CustomAppContext = {
  Component: NextComponentType<NextPageContext, {}, {}>
  AppTree: AppTreeType
  ctx: CustomNextPageContext
  router: Router
  locales: string
}

const queryClient = new QueryClient()

initSentry()

const CustomApp = ({ Component, pageProps, err, messages }: AppProps & Props) => {
  const router = useRouter()
  const lang = router.locale
  const languages = router.locales
  // The first url part after the host, is dynamic ([lang])
  // because it's dynamic, it can also be gassan/non-existing, or gassan/non-existing/something-else.
  // we check if it exist (can also be null for the homepage), and if it does, check if it's either "nl" or "en"
  let shouldRender404 = false
  if (lang !== null && !['nl', 'en', 'sg', 'de'].includes(lang)) {
    shouldRender404 = true
  }

  // If router.asPath is /sg or includes /sg/ we only want to show url's that have /rolex
  // E.g. /sg/contact is invalid, /sg/rolex/day-date is valid
  if (
    (router.asPath === '/sg' || router.asPath.includes('/sg/')) &&
    !router.asPath.includes('/rolex')
  ) {
    shouldRender404 = true
  }

  return (
    <>
      <Debug />
      <Scripts />
      <Head />
      <Fonts />
      <PinterestTracking />
      <DefaultSeo />
      <Awin />
      <ThemeProvider theme={theme}>
        <LocalizationProvider initialLang={lang} languages={languages} router={router}>
          <UrqlClientProvider lang={lang ? lang : undefined}>
            <QueryClientProvider client={queryClient}>
              <CheckoutProvider>
                <Tracking />
                <LoadCart />
                <NotificationsProvider>
                  <LazyMotion features={domAnimation}>
                    <TopLoadingBar />
                    <ExitPreview />
                    <MembershipModal />
                    <Cookies />
                    {shouldRender404 ? (
                      <ErrorPage statusCode={404} />
                    ) : (
                      <Component {...pageProps} err={err} lang={lang || 'nl'} /> // TODO: remove from here
                    )}
                  </LazyMotion>
                </NotificationsProvider>
              </CheckoutProvider>
            </QueryClientProvider>
          </UrqlClientProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  )
}

CustomApp.getInitialProps = async ({ Component, ctx, router }: CustomAppContext) => {
  let { locale: lang } = router

  // if (router.route === '/404') {
  //   // On a 404 page we need to parse the lang from the url
  //   const errorLang = router.asPath.split('/').filter((t) => t !== '')[0]
  //   // If errorLang = nl | en | sg return it as is (sg as en), so the error page is set to the correct lang
  //   if (['en', 'sg', 'nl'].includes(errorLang)) {
  //     lang = errorLang === 'sg' ? 'en' : errorLang
  //   }
  // }

  let pageProps = {}
  // This makes lang available in every getInitialProps call
  // e.g Page.getInitialProps({lang})
  if (Component.getInitialProps) {
    // @ts-ignore
    pageProps = await Component.getInitialProps({ ...ctx, lang })
  }

  // Get initial messages
  const messages = await (
    await import(`../components/Localization/messages/${lang || 'en'}`)
  ).default

  // Reset downshift id count
  if (typeof window === 'undefined') {
    resetIdCounter()
  }

  return {
    pageProps,
    messages,
  }
}

// @ts-ignore
export default appWithTranslation(CustomApp)
