import { cx } from '@linaria/core'
import { FC, HTMLAttributes } from 'react'
import { ThemeSystemProps, filterProps } from 'theme-system'
import { Theme, parseAll } from '@config/theme'
import { defaultStyles, variantStyles } from './LinariaHeading.elements'

type Props = Omit<HTMLAttributes<HTMLElement>, 'color'> &
  Pick<
    ThemeSystemProps<Theme>,
    | 'color'
    | 'fontFamily'
    | 'fontSize'
    | 'fontWeight'
    | 'm'
    | 'my'
    | 'mx'
    | 'mt'
    | 'mr'
    | 'mb'
    | 'ml'
  > & {
    as: 'div' | 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
    variant?: 'hXl' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  }

export const LinariaHeading: FC<Props> = ({
  children,
  className,
  color = 'shade800',
  variant = 'regular',
  mb = { _: '6', medium: '8', xlarge: '10' },
  as = 'p',
  ...rest
}) => {
  const Element = as
  return (
    <Element
      data-variant={variant}
      className={cx(parseAll({ ...rest, mb, color }), defaultStyles, variantStyles, className)}
      {...filterProps(rest)}
    >
      {children}
    </Element>
  )
}
