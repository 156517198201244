import { cx } from '@linaria/core'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'
import { NavigationContent } from '@lib/kontent/navigation-helpers/normalize-navigation-content'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { HideSubItemShop } from '@components/Page/Header/shop-menu/HideSubItemShop'
import { hideMenuElement } from '@components/Page/Header/styles'
import { Container, Flex, Icon } from '@gassan-ui'
import MenuButton from '../MenuButton'
import { linkButton } from '../MenuButton.styles'
import { MenuLabel } from '../MenuLabel'
import { MenuList } from '../MenuList'
import { HeaderShopMenuDiamonds } from './Diamonds'
import { HeaderShopMenuJewelry } from './Jewelry'
import { HeaderShopMenuRings } from './Rings'
import { HeaderShopMenuWatches } from './Watches'
import * as styles from './styles'

type HeaderShopMenuProps = {
  content: NavigationContent
  isVisible: boolean
}

export const HeaderShopMenu: FC<HeaderShopMenuProps> = ({ content, isVisible }) => {
  const { t } = useTranslation(['dropdown', 'navigation'])
  const [active, setActive] = useState('watches')

  return (
    <Container className={cx(styles.grid, hideMenuElement)} data-is-visible={isVisible}>
      <div>
        <MenuLabel>{t('shop.categories', { ns: 'dropdown' })}</MenuLabel>
        <MenuList space="large">
          <li>
            <MenuButton
              variant="large"
              onMouseEnter={() => setActive('watches')}
              title={t('shop.watches', { ns: 'dropdown' })}
              isActive={active === 'watches'}
            >
              {t('shop.watches', { ns: 'dropdown' })}
            </MenuButton>
          </li>
          <li>
            <MenuButton
              variant="large"
              onMouseEnter={() => setActive('diamonds')}
              title={t('shop.diamonds', { ns: 'dropdown' })}
              isActive={active === 'diamonds'}
            >
              {t('shop.diamonds', { ns: 'dropdown' })}
            </MenuButton>
          </li>
          <li>
            <MenuButton
              variant="large"
              onMouseEnter={() => setActive('rings')}
              title={t('shop.weddingRings', { ns: 'dropdown' })}
              isActive={active === 'rings'}
            >
              {t('shop.weddingRings', { ns: 'dropdown' })}
            </MenuButton>
          </li>
          <li>
            <MenuButton
              variant="large"
              onMouseEnter={() => setActive('jewelry')}
              title={t('shop.jewelry', { ns: 'dropdown' })}
              isActive={active === 'jewelry'}
            >
              {t('shop.jewelry', { ns: 'dropdown' })}
            </MenuButton>
          </li>
          <li>
            <LocalizedLink href={t('accessories', { ns: 'navigation' })} passHref>
              <a
                className={linkButton}
                data-variant="large"
                title={t('shop.accessories', { ns: 'dropdown' })}
              >
                <Flex alignItems="center">
                  <span>{t('shop.accessories', { ns: 'dropdown' })}</span>
                  <Icon icon="arrow-right" size={32} ml=".5rem" />
                </Flex>
              </a>
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink href={t('giftcards', { ns: 'navigation' })} passHref>
              <a
                className={linkButton}
                data-variant="large"
                title={t('shop.giftcard', { ns: 'dropdown' })}
              >
                <Flex alignItems="center">
                  <span>{t('shop.giftcard', { ns: 'dropdown' })}</span>
                  <Icon icon="arrow-right" size={32} ml=".5rem" />
                </Flex>
              </a>
            </LocalizedLink>
          </li>
        </MenuList>
      </div>
      <div className={styles.wrapSubItem}>
        <HideSubItemShop isVisible={active === 'watches'}>
          <HeaderShopMenuWatches {...content.watches} />
        </HideSubItemShop>
        <HideSubItemShop isVisible={active === 'diamonds'}>
          <HeaderShopMenuDiamonds {...content.diamonds} />
        </HideSubItemShop>
        <HideSubItemShop isVisible={active === 'rings'}>
          <HeaderShopMenuRings {...content.weddingRings} />
        </HideSubItemShop>
        <HideSubItemShop isVisible={active === 'jewelry'}>
          <HeaderShopMenuJewelry {...content.jewelry} />
        </HideSubItemShop>
      </div>
    </Container>
  )
}
