import { TrustPilotWidget } from '@components/TrustPilotWidget'
import { Box } from '../../gassan-ui'

const FooterTrustPilotWidget = () => {
  return (
    <Box
      mr={{ xlarge: '-2.75rem' }}
      flex={{ _: '0 0 100%', large: 'initial' }}
      display={{ _: 'flex', large: 'initial' }}
      justifyContent={{ _: 'center', large: 'flex-start' }}
      mt={{ _: '1.5rem', large: 'initial' }}
      flexGrow={1}
      as="span"
    >
      <TrustPilotWidget
        templateId="5419b732fbfb950b10de65e5"
        businessUnitId="552f9c6e0000ff00057ecd72"
        height="20px"
        width="350px"
      />
    </Box>
  )
}

export default FooterTrustPilotWidget
