import { cx } from '@linaria/core'
import React, { FC, HTMLAttributes } from 'react'
import { ThemeSystemProps, filterProps } from 'theme-system'
import { Theme, parseAll } from '@config/theme'

type BoxProps = Omit<HTMLAttributes<HTMLElement>, 'color'> &
  ThemeSystemProps<Theme> & {
    as?: React.ElementType
  }

export const LinariaBox: FC<BoxProps> = ({ children, className, as = 'div', ...rest }) => {
  const Element = as
  return (
    <Element className={cx(parseAll(rest), className)} {...filterProps(rest)}>
      {children}
    </Element>
  )
}
