import NextLink from 'next/link'
import { FC } from 'react'
import { WatchesContent } from '@lib/kontent/navigation-helpers/get-watches-content'
import { Link } from '@components/gassan-ui'
import { HeaderImageColumn } from '../../image-column'
import { HeaderImageListColumn } from '../../image-list-column'
import { HeaderLinkColumn } from '../../link-column'
import { spacer } from '../styles'

type HeaderMobileNavJewelry = WatchesContent

export const Jewelry: FC<HeaderMobileNavJewelry> = ({ linkList, brandList, imageLink }) => {
  return (
    <>
      <HeaderLinkColumn
        title={linkList.title}
        readMoreLink={
          linkList.viewMore && (
            <NextLink href={linkList.viewMore.url} passHref>
              <Link variant="blue">{linkList.viewMore.label}</Link>
            </NextLink>
          )
        }
        links={linkList.links}
        linkSpace="regular"
      />
      <div className={spacer} />
      <HeaderImageListColumn
        title={brandList.title}
        readMoreLink={
          brandList.viewMore && (
            <NextLink href={brandList.viewMore.url} passHref>
              <Link variant="blue">{brandList.viewMore.label}</Link>
            </NextLink>
          )
        }
        links={brandList.links}
      />
      <div className={spacer} />
      <HeaderImageColumn {...imageLink} />
    </>
  )
}
