// @ts-strict-ignore
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { useAuth } from '@lib/hooks/use-auth'
import { useMembershipModal } from '@lib/hooks/use-membership-modal'
import { useWishlist } from '@lib/hooks/use-wishlist'
import { useCart } from '@lib/hooks/useCart'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Icon } from '@components/gassan-ui'
import { NavActiveOptions } from '../HeaderContent'
import { RolexOrTudorPlaque } from '../RolexOrTudorPlaque'
import LanguageSwitch from './LanguageSwitch'
import * as styles from './SecondaryNav.styles'

type HeaderNavigationSecondaryNavProps = {
  onClickItem: (key: NavActiveOptions) => void
  isLightText?: boolean
}

export const HeaderNavigationSecondaryNav: FC<HeaderNavigationSecondaryNavProps> = ({
  isLightText,
  onClickItem,
}) => {
  const { articleIds } = useWishlist()
  const { isLoggedIn } = useAuth()
  const { setShowMembershipModalVariant } = useMembershipModal()
  const { t } = useTranslation(['navigation', 'global'])
  const cart = useCart()
  const totalItems = cart ? cart.totalItems : 0

  return (
    <div className={styles.parent}>
      <button
        className={styles.iconButton}
        onClick={() => onClickItem('search')}
        data-secondary-nav-element
      >
        <Icon size={[32, 40]} icon="search" />
      </button>
      <LocalizedLink href={t('secondaryNav.stores.url', { ns: 'navigation' })} passHref>
        <a className={styles.iconButton} data-desktop-only data-secondary-nav-element>
          <Icon size={[32, 40]} icon="map-marker" />
        </a>
      </LocalizedLink>
      {isLoggedIn ? (
        <LocalizedLink href={t('account.wishlist.url', { ns: 'navigation' })} passHref>
          <a className={styles.iconButton} data-desktop-only data-secondary-nav-element>
            <Icon size={[32, 40]} icon="heart" />
            {articleIds && articleIds.length > 0 && (
              <span
                className={styles.wishlistIndicator}
                data-is-light-text={isLightText ? '' : undefined}
                data-is-two-digits={articleIds.length > 9 ? '' : undefined}
              >
                {articleIds.length}
              </span>
            )}
          </a>
        </LocalizedLink>
      ) : (
        <button
          className={styles.iconButton}
          data-desktop-only
          data-secondary-nav-element
          onClick={() => setShowMembershipModalVariant('wishlist')}
        >
          <Icon size={[32, 40]} icon="heart" />
        </button>
      )}
      <LocalizedLink href={t('secondaryNav.cart.url', { ns: 'navigation' })} passHref>
        <a className={styles.iconButton} data-secondary-nav-element>
          <Icon size={[32, 40]} icon="shopping-bag" />
          {totalItems > 0 && (
            <span
              className={styles.wishlistIndicator}
              data-is-light-text={isLightText ? '' : undefined}
              data-is-two-digits={articleIds.length > 9 ? '' : undefined}
              data-test-id="cart-count-indicator"
            >
              {totalItems}
            </span>
          )}
        </a>
      </LocalizedLink>
      <LanguageSwitch />
      <div className={styles.rolexClock}>
        <RolexOrTudorPlaque />
      </div>
    </div>
  )
}
