// @ts-strict-ignore
import { rem } from 'polished'
import React, { ButtonHTMLAttributes, FC } from 'react'
import styled from 'styled-components'
import { bodyRegularStyle } from '../Typography/styles'

const StyledMenuButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isBold', 'isExpanded'].includes(prop),
})<{ isBold: boolean; isExpanded: boolean; variant: 'align-right' | 'align-left' }>`
  ${bodyRegularStyle};
  display: flex;
  align-items: center;
  outline: 0;
  font-weight: ${(p) => (p.isBold ? 600 : 400)};

  ${(p) => (p.variant === 'align-left' ? `margin-right: 1.5rem;` : `margin-left: 1.5rem;`)}

  img {
    width: ${rem(11)};
    height: ${rem(11)};
    margin-left: 0.5rem;
    margin-top: 2px;
    transform: rotate(${(p) => (p.isExpanded ? '180deg' : 0)});
  }
`

const Indicator = styled.div`
  background: ${(p) => p.theme.colors.shades[800]};
  width: ${rem(20)};
  height: ${rem(20)};
  font-family: ${(p) => p.theme.fonts.body};
  font-size: ${rem(13)};
  color: #fff;
  border-radius: ${rem(10)};
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  padding-right: 1px;
`

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  isExpanded?: boolean
  activeFilters?: number
  variant?: 'align-right' | 'align-left'
}

export const FilterMenuButton: FC<Props> = React.forwardRef<HTMLButtonElement, Props>(
  ({ isExpanded, activeFilters, children, variant = 'align-left', ...rest }, ref) => {
    let src = '/images/chevron.svg'
    const hasFilters = activeFilters > 0
    if (hasFilters || isExpanded) {
      src = '/images/chevron-bold-down.svg'
    }

    return (
      <StyledMenuButton
        {...rest}
        isBold={hasFilters || isExpanded}
        isExpanded={isExpanded}
        variant={variant}
      >
        {children} {activeFilters > 0 && <Indicator>{activeFilters}</Indicator>}{' '}
        <img src={src} alt="" />
      </StyledMenuButton>
    )
  },
)
