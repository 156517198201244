import Portal from '@reach/portal'
import { AnimatePresence, motion } from 'framer-motion'
import { useRouter } from 'next/router'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Notification, NotificationBody, breakpoints, theme } from '@gassan-ui'

const NotificationContainer = styled(motion.div)`
  position: fixed;
  bottom: 0;
  width: 100%;
  @media screen and (min-width: ${breakpoints.small}) {
    pointer-events: none;
    display: flex;
    justify-content: center;
    bottom: 3.5rem;
  }
`

// The prevents the whole page from rerendering when a notifications is added
const Memoize = React.memo(({ children }) => <>{children}</>)

type NotificationsUpdate = {
  addNotification: (args: NotificationBody) => void
  removeNotification: () => void
}

const getId = (): string => Math.random().toString(36).substr(5)

const NotificationsUpdateContext = createContext<NotificationsUpdate>({
  addNotification: (notification: NotificationBody) => null,
  removeNotification: () => null,
})

export const NotificationsProvider: React.FC<{}> = ({ children }) => {
  const [notification, setNotification] = useState<NotificationBody | null>(null)
  const { pathname } = useRouter()

  const addNotification = useCallback(
    (notification) => {
      setNotification({ ...notification, id: getId() })
    },
    [setNotification],
  )

  const removeNotification = () => setNotification(null)

  const onRequestClose = () => setNotification(null)

  useEffect(() => {
    // Hide notification on route change
    removeNotification()
  }, [pathname])

  return (
    <>
      <NotificationsUpdateContext.Provider value={{ addNotification, removeNotification }}>
        <Memoize>{children}</Memoize>
      </NotificationsUpdateContext.Provider>
      <Portal>
        <AnimatePresence>
          {notification && (
            <NotificationContainer
              key={getId()}
              initial={{ opacity: 0, y: '50%', zIndex: theme.zIndices.notifications - 1 }}
              animate={{ opacity: 1, y: 0, zIndex: theme.zIndices.notifications }}
              exit={{
                opacity: 0,
                zIndex: theme.zIndices.notifications - 1,
              }}
              // transition={{ duration: 0.2 }}
            >
              <Notification requestClose={onRequestClose} {...notification} />
            </NotificationContainer>
          )}
        </AnimatePresence>
      </Portal>
    </>
  )
}

export function useNotifications() {
  const ctx = useContext(NotificationsUpdateContext)
  if (typeof ctx === 'undefined') {
    throw new Error('useNotificationsUpdate must be within a NotificationsUpdate Provider')
  }
  return ctx
}
