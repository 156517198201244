// @ts-strict-ignore
import { ButtonHTMLAttributes, FC } from 'react'
import styled from 'styled-components'
import { CleanButton } from './CleanButton'
import { Icon } from './Icon'

const Button = styled(CleanButton)<{ isOnWishlist }>`
  color: ${(p) => (p.isOnWishlist ? p.theme.colors.shades[800] : p.theme.colors.shades[500])};
  &:hover,
  &:focus {
    color: ${(p) => p.theme.colors.shades[600]};
  }
`

export type WishListButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isOnWishlist: boolean
  onClick: () => void
}

export const WishListButton: FC<WishListButtonProps> = ({ isOnWishlist, onClick, ...rest }) => {
  return (
    <Button
      isOnWishlist={isOnWishlist}
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      {...rest}
    >
      <Icon icon={isOnWishlist ? 'heart-filled' : 'heart'} fill="pink" />
    </Button>
  )
}
