// @ts-strict-ignore
import shouldForwardProp from '@styled-system/should-forward-prop'
import { rem } from 'polished'
import React, { FC, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { breakpoints, theme } from '../theme'
import { StyledTextProps, allStyledSystemFunctions } from './config'

const defaultStyles = css`
  font-family: ${theme.fonts.specials};
  letter-spacing: 0.0875rem;
  text-transform: uppercase;
`
const one = css`
  ${defaultStyles};
  font-size: ${rem(9)};
  line-height: 1.4;

  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: ${rem(15)};
  }
  @media screen and (min-width: ${breakpoints.large}) {
    font-size: 1.25rem;
  }
`

const two = css`
  ${defaultStyles};
  font-size: ${rem(9)};
  line-height: 1.4;

  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: ${rem(15)};
  }
`

const textStyles = {
  one,
  two,
}

export type SpecialSpanProps = HTMLAttributes<HTMLElement> &
  StyledTextProps & {
    variant: 'one' | 'two'
  }

export const SpecialSpan: FC<SpecialSpanProps> = styled<React.FC<SpecialSpanProps>>(
  React.forwardRef((props: SpecialSpanProps, ref: any) => {
    let As = props.as || 'span'
    return <As {...props} ref={ref}></As>
  }),
).withConfig({ shouldForwardProp })(
  (props) => props.variant && textStyles[props.variant],
  allStyledSystemFunctions,
)

SpecialSpan.defaultProps = {
  color: 'shades.800',
}
