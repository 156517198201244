import React, { FC } from 'react'
import { cn } from '@lib/cn'
import { Icon, UIIconProps } from '../Icon'

export const NotificationIcon: FC<UIIconProps> = ({ className, ...props }) => {
  return (
    <Icon
      // using important to override the defaults in gassan-ui/Icon
      className={cn('!h-10 !w-10 shrink-0 bg-shade-50 sm:!h-14 sm:!w-14', className)}
      {...props}
    />
  )
}
