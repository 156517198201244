import { FC } from 'react'
import { HouseOfGassanContent } from '@lib/kontent/navigation-helpers/get-house-of-gassan-content'
import { HeaderImageColumn } from '../image-column'
import { spacer } from './styles'

type HeaderMobileNavHouseOfGassanProps = {
  columns: HouseOfGassanContent
}

export const HeaderMobileNavHouseOfGassan: FC<HeaderMobileNavHouseOfGassanProps> = ({
  columns,
}) => {
  return (
    <>
      {columns.map((column, key) => (
        <>
          {key > 0 && <div className={spacer} />}
          <HeaderImageColumn {...column} key={key} />
        </>
      ))}
    </>
  )
}
