import { Box, CleanButton, Icon, breakpoints } from '@gassan-ui'
import { cn } from '@lib/cn'
import { rem } from 'polished'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { ColumnHeading } from './Columns'

type Props = {
  title: string
  canCollapse?: boolean
}

const HeadingButton = styled(CleanButton)<{ isOpen: boolean; canCollapse?: boolean }>`
  padding: 0;
  margin: 0;
  outline: 0;
  border: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.colors.shades[400]};
  min-height: ${rem(72)};

  ${(p) =>
    !p.canCollapse &&
    `
      pointer-events: none;
    `}

  svg {
    ${(p) =>
      p.isOpen &&
      `
        transform: rotate(180deg);
      `}
  }
  @media screen and (min-width: ${breakpoints.medium}) {
    min-height: ${rem(98)};
  }

  @media screen and (min-width: ${breakpoints.large}) {
    pointer-events: none;
    min-height: auto;
    margin-bottom: 2rem;
    border-top: 0;
    svg {
      display: none;
    }
  }
`

const Column: FC<Props> = ({ title, canCollapse, children }) => {
  const [isOpen, setIsOpen] = useState(!canCollapse)

  const toggle = () => {
    if (canCollapse) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <div>
      <HeadingButton isOpen={isOpen} onClick={toggle} canCollapse={canCollapse} id={title}>
        <ColumnHeading variant="h4" lineHeight={[0, 0, 0, 1.4]} mb={0} color="shades.800">
          {title}
        </ColumnHeading>
        {canCollapse && <Icon size={32} icon="chevron-down"></Icon>}
      </HeadingButton>
      <div
        className={cn(
          'duration-250 h-0 max-h-0 overflow-hidden transition-all',
          'data-[is-open]:h-auto data-[is-open]:max-h-[500px]',
          'lg:h-auto lg:max-h-[600px]',
        )}
        data-is-open={isOpen ? '' : undefined}
        // isOpen={isOpen}
      >
        <Box pb={[3]}>{children}</Box>
      </div>
    </div>
  )
}

export default Column
