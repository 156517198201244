// @ts-strict-ignore
import shouldForwardProp from '@styled-system/should-forward-prop'
import styled, { css } from 'styled-components'
import { Box } from '../Layout'

export const MenuItem = styled(Box).withConfig({
  shouldForwardProp,
})<{ isActive?: boolean }>`
  transition: all 0.2s ease-in-out;
  font-family: ${(p) => p.theme.fonts.body};
  color: ${(p) => p.theme.colors.shades[800]};
  height: 2.5rem;
  padding: 0 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(p) =>
    p.isActive &&
    css`
      background: ${(p) => p.theme.colors.shades[100]} !important;
      font-weight: bold;
      &:hover {
        background: ${(p) => p.theme.colors.shades[100]};
        font-weight: bold;
      }
    `}

  &[aria-selected='true'] {
    background: ${(p) => p.theme.colors.shades[100]};
  }
`

MenuItem.defaultProps = {
  as: 'li',
}
