// @ts-strict-ignore
import { useArticlesByIdQuery } from '@generated'
import Cookies from 'js-cookie'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useState } from 'react'
import { formatPrice } from '@lib/formatPrice'
import { imgix } from '@lib/imigx'
import { RECENTLY_VIEWED_STORAGE_KEY } from '@components/_product-detail/SaveRecentlyViewed'
import { MenuLabel } from '../MenuLabel'
import Result from './Result'

const List: FC<{ ids: string[] }> = ({ ids }) => {
  const { t } = useTranslation('dropdown')
  const [result] = useArticlesByIdQuery({
    variables: { ids },
  })

  if (result.fetching || result.error) {
    return null
  }

  return (
    <>
      <MenuLabel>{t('search.recentlyViewed')}</MenuLabel>
      {result.data?.articlesById &&
        result.data.articlesById.map((article) => (
          <Result
            key={`result-${article.id}`}
            variant="product"
            href={`/p/${article.urlSlug}`}
            title={article.brand}
            subtitle={article.description}
            price={
              article.salePrice
                ? formatPrice(article.salePrice)
                : article.price
                  ? formatPrice(article.price)
                  : undefined
            }
            image={imgix(article.images[0], { width: 65 })}
          />
        ))}
    </>
  )
}

const RecentlyViewed: FC = () => {
  const [ids, setIds] = useState([])
  useEffect(() => {
    const recentlyViewed = Cookies.get(RECENTLY_VIEWED_STORAGE_KEY)
    if (recentlyViewed) {
      setIds(JSON.parse(recentlyViewed))
    }
  }, [])

  if (ids.length) {
    return <List ids={ids} />
  }

  return null
}

export default RecentlyViewed
