import { FC, InputHTMLAttributes, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { breakpoints } from '../theme'
import { InputStatusProp, baseStyles } from './styles'

export const StyledInput = styled.input<InputStatusProp>`
  ${baseStyles};
  height: 3rem;
  color: ${(p) => p.theme.colors.shades[800]};
  padding: 0 0.75rem;

  @media screen and (min-width: ${breakpoints.medium}) {
    height: 3.5rem;
  }

  & + label {
    top: 50%;
    color: ${(p) => p.theme.colors.shades[500]};
    transform: translateY(-50%);
  }

  &:focus,
  &.filled {
    padding-top: 0.75rem;
    padding-bottom: 0.25rem;
    & + label {
      transform: translateY(calc(-50% - 0.75rem));
      font-size: 0.625rem;
    }
  }

  &:focus + label {
    color: ${(p) => p.theme.colors.tabaccoBrown};
  }

  ${(p) =>
    p.status === 'error' &&
    css`
      &.filled + label {
        color: ${p.theme.colors.error};
      }
    `};

  ${(p) =>
    p.status === 'valid' &&
    `
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 20.3636L18.3333 24C18.3333 24 19.5 22 21 20C22.5 18 25 16 25 16' stroke='%234CAF50'%0AstrokeWidth='2' strokeLinejoin='round' fill='none' /%3E%3C/svg%3E");
      background-position: calc(100% - 0.5rem) 60%;
      background-repeat: no-repeat;
      background-size: 2rem 2rem;
      padding-right: 2rem;

      @media screen and (min-width: ${breakpoints.small}) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath d='M15 20.3636L18.3333 24C18.3333 24 19.5 22 21 20C22.5 18 25 16 25 16' stroke='%234CAF50' stroke-width='2' stroke-linejoin='round' fill='none' /%3E%3C/svg%3E");
        background-position: calc(100% - 0.25rem) 80%;
        background-repeat: no-repeat;
        background-size: 2.5rem 2.5rem;
      }
    `};

  @media screen and (min-width: ${breakpoints.small}) {
    padding: 0 1rem;
  }
`

export type InputProps = InputStatusProp &
  InputHTMLAttributes<HTMLInputElement> & {
    value?: string
  }

export const Input: FC<InputProps> = ({ onChange, status, value, name, ...rest }) => {
  const [isFilled, setIsFilled] = useState(false)

  useEffect(() => {
    if (typeof value === 'string') {
      setIsFilled(value.length > 0)
    }
  }, [value])

  return (
    <>
      {isFilled}
      <StyledInput
        status={status}
        onChange={onChange}
        value={value}
        className={isFilled ? 'filled' : undefined}
        id={name}
        data-field-id={name}
        name={name}
        {...rest}
      />
    </>
  )
}
