// @ts-strict-ignore
import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { getCookieSettings } from '@lib/cookies'
import { genericPushToTrackingLayer } from '@lib/data-layer-social-ads/generic-push-to-tracking-layer'
import { defaultEmarsysTracking } from '@lib/emarsys-tracking/default-emarsys-tracking'
import { useAuth } from '@lib/hooks/use-auth'
import { useCart } from '@lib/hooks/useCart'
import { useEmarsysStore } from '@lib/use-emarsys-store'

const Tracking: FC = () => {
  const router = useRouter()
  const cart = useCart()
  const { me } = useAuth()
  const { scriptIsLoaded } = useEmarsysStore()
  const cookieSettings = getCookieSettings()
  const { NEXT_PUBLIC_STAGE } = process.env

  useEffect(() => {
    // Hotjar
    if (cookieSettings.includes('analytical') && NEXT_PUBLIC_STAGE === 'production') {
      import('react-hotjar')
        .then((mod) => mod.hotjar)
        .then((hotjar) => hotjar.initialize(283874, 6))
    }

    // Tag manager
    if (
      cookieSettings.includes('analytical') &&
      cookieSettings.includes('personal') &&
      ['staging', 'production'].includes(NEXT_PUBLIC_STAGE)
    ) {
      TagManager.initialize({ gtmId: 'GTM-WRDV5CC' })
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    const emarsysExcludePageTypes = [
      '/p/',
      '_category-page',
      'confirmed',
      '_brand-page',
      '/shopping-cart',
      '/winkelmand',
    ]
    const homepagePathnames = ['/']
    const isHomepage = homepagePathnames.some((pathname) => router.pathname === pathname)
    genericPushToTrackingLayer('pageview')
    // Fire default emarsys tracking, on all pages except:
    // - Product pages
    // - Category/brand pages
    // - Confirm page
    // - Cart
    // - Homepage
    if (
      !isHomepage &&
      emarsysExcludePageTypes.every((pageType) => !router.pathname.includes(pageType)) &&
      scriptIsLoaded
    ) {
      const customerId = me && me.dafUser && me.dafUser.klant_nr
      if (cart) {
        defaultEmarsysTracking({ customerId, cart })
      }
    }
  }, [router.asPath, router.pathname, scriptIsLoaded]) //eslint-disable-line

  return null
}

export default Tracking
