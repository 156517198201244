// @ts-strict-ignore
import Downshift from 'downshift'
import { FC } from 'react'
import { MarginBottomProps, MarginTopProps } from 'styled-system'
import { Box, Radio } from '@gassan-ui'
import { Item } from '.'
import { FilterMenuButton } from './FilterMenuButton'
import { Menu, MenuList } from './Menu'

export type FilterMenuRadioProps = MarginBottomProps &
  MarginTopProps & {
    items: Item[]
    value: string | number
    onChange: (value: string) => void
    label: string
    variant?: 'align-right' | 'align-left'
  }

export const FilterMenuRadio: FC<FilterMenuRadioProps> = ({
  items,
  value,
  onChange,
  label,
  mb = '1rem',
  mt,
  variant = 'align-left',
}) => {
  return (
    <Downshift
      itemToString={(item) => (item ? item.value : null)}
      onSelect={(item) => {
        if (item) {
          onChange(item.value)
        }
      }}
      selectedItem={null}
    >
      {({
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getRootProps,
        getItemProps,
        isOpen,
      }) => {
        return (
          <Box {...getRootProps()} position="relative" display="inline-block" mb={mb} mt={mt}>
            <FilterMenuButton {...getToggleButtonProps()} isExpanded={isOpen} variant={variant}>
              {label}
            </FilterMenuButton>
            {isOpen && (
              <Menu {...getMenuProps()} pt="1rem" variant={variant}>
                <MenuList>
                  {items.map((item, index) => (
                    <li {...getItemProps({ item, index })} key={`item-${index}`}>
                      <Radio
                        id={item.value.toString()}
                        label={item.label}
                        value={item.value}
                        checked={value ? value === item.value : false}
                        readOnly
                        onClick={() => {}}
                        mb={index === items.length - 1 ? 0 : '.5rem'}
                      />
                    </li>
                  ))}
                </MenuList>
              </Menu>
            )}
          </Box>
        )
      }}
    </Downshift>
  )
}
