import { parse } from '@config/theme';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import LocalizedLink from '../Localization/LocalizedLink';
import { Modal } from '../Modal';
import { NewAtGassan } from '../NewAtGassan';
import { Button, Link } from '../gassan-ui';
import { LinariaText } from '../gassan-ui/Typography/LinariaText';
import { useMembershipModalStatus } from './hooks/use-membership-modal-status';
export const MembershipModal: FC = () => {
  const {
    showMembershipVariant,
    handleRequestClose
  } = useMembershipModalStatus();
  const {
    t
  } = useTranslation('other');
  if (!showMembershipVariant) return null;
  return <Modal defaultOpen title={t('myGassanMembership')} onOpenChange={open => {
    if (!open) {
      handleRequestClose();
    }
  }}>
      <LinariaText variant="regular" mb="6">
        {showMembershipVariant === 'brand' && t('become-member-modal-brand', {
        ns: 'other'
      })}
        {showMembershipVariant === 'wishlist' && t('become-member-modal-wishlist', {
        ns: 'other'
      })}
      </LinariaText>
      <NewAtGassan variant="slim" />
      <div className={buttons}>
        <LocalizedLink href={t('account.create.url', {
        ns: 'navigation'
      })} passHref>
          <Button as="link" variant="dark" className="w-full">
            {t('becomeMember', {
            ns: 'account'
          })}
          </Button>
        </LocalizedLink>
        <LocalizedLink href={t('account.login.url', {
        ns: 'navigation'
      })} passHref>
          <Link as="link" variant="brown">
            {t('account.login.title', {
            ns: 'navigation'
          })}
          </Link>
        </LocalizedLink>
      </div>
    </Modal>;
};
const buttons = parse({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}, "baayufk");

require("./index.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.tsx");