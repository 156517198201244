import { cx } from '@linaria/core'
import { HTMLMotionProps, motion } from 'framer-motion'
import { FC } from 'react'
import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading'
import { Icon } from '@gassan-ui'
import * as styles from './Subrow.styles'

const motionPropsParent = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: 'easeOut', duration: 0.2, delay: 0.2 },
}

const motionPropsContent = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: 'easeOut', duration: 0.2, delay: 0.2 },
}

type Props = HTMLMotionProps<'div'> & {
  label: string
  onClick: () => void
  isActive: boolean
}

const Subrow: FC<Props> = ({ children, label, onClick, isActive, ...rest }) => {
  return (
    <motion.div {...motionPropsParent} {...rest}>
      <motion.button
        className={cx(styles.subrowButton, styles.linkStyle)}
        data-status-active={isActive ? '' : undefined}
        onClick={onClick}
      >
        <LinariaHeading variant="h2" as="h2" mb="0">
          {label}
        </LinariaHeading>
        <Icon icon={isActive ? 'chevron-up-small' : 'chevron-down-small'}></Icon>
      </motion.button>
      {isActive && (
        <motion.div {...motionPropsContent} className={styles.subrowContent}>
          {children}
        </motion.div>
      )}
    </motion.div>
  )
}

export default Subrow
