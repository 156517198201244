// @ts-strict-ignore
import shouldForwardProp from '@styled-system/should-forward-prop'
import React, { FC, HTMLAttributes } from 'react'
import styled from 'styled-components'
import { StyledTextProps, allStyledSystemFunctions } from './config'

export type SpanProps = Omit<HTMLAttributes<HTMLSpanElement>, 'color'> & StyledTextProps

export const Span: FC<SpanProps> = styled<React.FC<StyledTextProps>>(
  React.forwardRef((props: StyledTextProps, ref: any) => {
    let As = props.as || 'span'
    return <As {...props} ref={ref}></As>
  }),
).withConfig({ shouldForwardProp })(allStyledSystemFunctions)

Span.defaultProps = {
  lineHeight: 1,
}
